import React, { useState, useEffect, useContext } from "react";
import { Box, Text, Form, FormField, TextInput, Button } from "grommet";
import moment from "moment";
import { Redirect } from "react-router";

import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
const GeneralInfo = ({
    roleInfo: { createdAt, createdBy = "-", name, description }
}) => {
    const [show, setshow] = useState(true);
    const [roleName, setRoleName] = useState(name);
    const [desc, setDesc] = useState(description);
    const [redirect, setredirect] = useState();
    const { storedNamespace } = useContext(NamespaceContext);
    useEffect(() => {
        setredirect(false);
        return () => {};
    }, [show]);

    const updateRole = async () => {        
      const res = await actions.UpdateRole(storedNamespace,  name, { newRoleDetails: 
            { name: roleName, description: desc }});
        if(res.success)
        {
            res.success("Role updated successfully");
            setredirect(true);
            setshow(true);
        }
    };

    return (
        <>
            {redirect ? (
                <Redirect
                    to={{
                        pathname: `/admin/roles/:${roleName}`,
                        state: { name: roleName, description: desc }
                    }}
                />
            ) : (
                <Box direction="column" pad="medium" gap="small">
                    <Text>
                        Created on:{" "}
                        {moment(createdAt).format(window.GLOBAL_DATE_FORMAT)}
                    </Text>
                    <Text>Created by: {createdBy}</Text>
                    <Text>Role Name: {roleName}</Text>
                    <Text>Description: {desc}</Text>
                    {show ? (
                        <Button>
                            <Box
                                align="center"
                                margin={{
                                    top: "medium"
                                }}
                                pad="small"
                                width="small"
                                background="brand"
                                round="xsmall"
                                onClick={() => setshow(false)}
                            >
                                Edit
                            </Box>
                        </Button>
                    ) : (
                        <Box
                            direction="column"
                            pad="medium"
                            align="start"
                            justify="center"
                        >
                            <Text size="large">
                                <strong>Edit the contents:</strong>
                            </Text>
                            <Form>
                                <Box width="medium">
                                    <FormField label="Role Name">
                                        <TextInput
                                            value={roleName}
                                            onChange={e =>
                                                setRoleName(e.target.value)
                                            }
                                        />
                                    </FormField>
                                    <FormField label="Description">
                                        <TextInput
                                            value={desc}
                                            onChange={e =>
                                                setDesc(e.target.value)
                                            }
                                        />
                                    </FormField>
                                </Box>
                                <Box
                                    direction="row"
                                    gap="small"
                                    justify="start"
                                >
                                    <Button>
                                        <Box
                                            pad="small"
                                            width="xsmall"
                                            background="brand"
                                            align="center"
                                            round="xsmall"
                                            onClick={() => updateRole()}
                                        >
                                            Save
                                        </Box>
                                    </Button>
                                    <Button onClick={() => setshow(true)}>
                                        <Box
                                            pad="small"
                                            width="xsmall"
                                            background="brand"
                                            align="center"
                                            round="xsmall"
                                        >
                                            Cancel
                                        </Box>
                                    </Button>
                                </Box>
                            </Form>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default GeneralInfo;
