import React, { useState, useContext } from "react";
import { DataTable, Text, Box, Button } from "grommet";
import { Trash } from "grommet-icons";
import moment from "moment";

import * as actions from "../../actions/uam-actions";
import EmptyList from "../EmptyList";
import AttachUser from "./AttachUser";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../toster";
import { confirmAlert } from "react-confirm-alert";

const Users = ({ users, groupName, setUpdate }) => {
    let [counter] = useState(0);
    const { storedNamespace } = useContext(NamespaceContext);
    const existingUsers = [];

    users.map((user) => existingUsers.push(user.emailAddress));
    const detachUser = async (emailAddress) => {
        await confirmAlert({
            title: "Confirm",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes, Delete it",
                    onClick: async () => {
                        let emails = existingUsers.filter((user1) => {
                            return user1 !== emailAddress;
                        });
                        let res = await actions.DetachUsersFromGroup(
                            storedNamespace,
                            { groupName, emails }
                        );
                        if (res.success) {
                            toast.success(
                                "User detached from the group successfully"
                            );
                        } else {
                            toast.warn("There is some issue. Please try again");
                        }
                        setUpdate(true);
                    }
                },
                {
                    label: "No",
                    onClick: () => {}
                }
            ]
        });
    };

    return (
        <>
            <Box
                direction="row"
                justify="end"
                gap="large"
                pad={{ right: "medium", bottom: "xxsmall" }}
                margin="xxsmall"
            >
                <AttachUser
                    groupName={groupName}
                    users={users}
                    setUpdate={setUpdate}
                />
            </Box>
            {users.length > 0 ? (
                <DataTable
                    margin="medium"
                    columns={[
                        {
                            property: "id",
                            header: <Text>Id</Text>,
                            render: () => {
                                counter = counter + 1;
                                return (
                                    <Box pad={{ vertical: "xsmall" }}>
                                        {counter}
                                    </Box>
                                );
                            }
                        },
                        {
                            property: "commonName",
                            header: <Text>User Name</Text>
                        },
                        {
                            property: "emailAddress",
                            header: <Text>Email</Text>
                        },
                        {
                            property: "createdAt",
                            header: <Text>Created On</Text>,
                            render: ({ createdAt }) =>
                                moment(createdAt).format(
                                    window.GLOBAL_DATE_FORMAT
                                )
                        },
                        {
                            property: "Action",
                            header: <Text>Action</Text>,
                            render: ({ emailAddress }) => (
                                <Box
                                    gap="small"
                                    direction="row"
                                    pad={{ vertical: "xsmall" }}
                                >
                                    <Button>
                                        <Trash
                                            size="20px"
                                            onClick={() =>
                                                detachUser(emailAddress)
                                            }
                                        />
                                    </Button>
                                </Box>
                            )
                        }
                    ]}
                    data={users}
                />
            ) : (
                <EmptyList />
            )}
        </>
    );
};

export default Users;
