import React, { useState } from "react";

import { getUserEmail, getAccessToken } from "../helpers/token.helper";
import * as actions from "../actions/uam-actions";

export const NamespaceContext = React.createContext(null);

export const NamespaceProvider = ({ children }) => {
    const [namespace, setNamespace] = useState("");
    const [namespaces, setNamespaces] = useState([]);

    return (
        <NamespaceContext.Provider
            value={{
                storedNamespace: namespace,
                namespaces: namespaces,
                updateNamespaceValue: newValue => {
                    setNamespace(newValue);
                },
                getNamespaces: async () => {
                    let accessToken = await getAccessToken();
                    let email = await getUserEmail(accessToken);
                    if (email) {
                        const serviceResponse = await actions.GetNamespacesByEmail(
                            email
                        );
                        setNamespaces(serviceResponse);
                    }
                }
            }}
        >
            {children}
        </NamespaceContext.Provider>
    );
};
