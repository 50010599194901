import { fetchToken } from "../helpers/token.helper";

const options = {
    method: "GET",
    headers: {
        "Content-Type": "application/json"
    }
};

// const statusCodes = {
//     Ok: 200,
//     Created: 201,
//     Accepted: 202,
//     NoContent: 204,
//     BadRequest: 400,
//     Unauthorized: 401,
//     Forbidden: 403,
//     NotFound: 404,
//     Conflict: 409,
//     InternalServerError: 500,
//     NotImplemeted: 501,
//     BadGateway: 502,
//     ServiceUnavailable: 503,
//     GatewayTimeout: 504
// };

const useFetch = async (url, opts) => {
    opts = opts
        ? {
              ...options,
              ...opts
          }
        : options;

    try {
        let token = await fetchToken();
        opts.headers.Authorization = token;
        const res = await fetch(
            `${process.env.REACT_APP_UAM_API_URL}${url}`,
            opts
        );

        let status = res.status;

        if (status === 200 || status === 201) {
            return res.json();
        } else if (status === 403) {
            if (window.location.pathname.includes("requestaccess")) {
                return res;
            } else if (window.location.search === "") {
                window.location.href = `/requestaccess`;
            } else {
                return res.json();
            }
        } else if (status === 500) {
            window.location.href = `/error/${res.statusText}`;
        } else if (status === 401) {
            localStorage.clear();
            window.location.href = `/login`;
        } else if (status === 404) {
            if (window.location.search.indexOf("?requestingFor") >= 0) {
                throw new Error("user/namespace/group isn't found. Please provide valid details");
            } else {
                window.location.href = `/error/${res.statusText}`;
                throw new Error(res.statusText);
            }
        } else {
            let { error } = await res.json();
            window.location.href = `/error/${error.message}`;
            throw new Error(error);
        }
    } catch (error) {
        const { message = "something went wrong" } = error;
        window.location.href = `/error/${message}`;
        throw new Error(message);
    }
};

export default useFetch;
