import React, { useState, useCallback } from 'react';
import { Box, Button, Layer, Form, TextInput, FormField, Text } from 'grommet';
import * as actions from "../../actions/uam-actions";
import debounce from '../../../src/helpers/debounce';
import toast from "../../components/toster";

const AddUser = ({ setupdate, updateCounts }) => {
    const [showLayer, setShowLayer] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [userData, setUserData] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [userSuccessText, setUserSuccessText] = useState("");

    const addUser = async event => {
        event.preventDefault();
        // //setShowLayer(false);        
        if (userData.length > 0) {
            const checkuserexists = await actions.GetUserByEmailAddress(searchKey);
            if (checkuserexists.user == null) {
                let userOutput = userData.filter(user => { return user.emailAddress === searchKey })
                if (userOutput.length >= 1) {
                    const userdetails = {
                        ntDomainId: userOutput[0].ntDomainId,
                        emailAddress: userOutput[0].emailAddress,
                        employeeNumber: userOutput[0].employeeNumber,
                        commonName: userOutput[0].commonName,
                        givenName: userOutput[0].givenName,
                        surname: userOutput[0].surname,
                    };
                    await actions.AddUser(userdetails);
                    setUserSuccessText("user added successfully, you may assign to group(s)");
                    toast.success("user added successfully", 10000);
                }
            } else {
                setUserSuccessText("user already exists");
            }
        }
        setSearchKey("");
        setUserData("");
        setSuggestions([]);
        //setupdate();
        // updateCounts();
    }

    const staticSuggestion = (text) => {
        return [
            {
                label: (React.createElement(Box, { onClick: (event) => event.stopPropagation(), pad: "small", style: { opacity: 0.5, cursor: 'pointer' } }, text))
            }
        ];
    };

    const filterSuggestion = async function (searchval) {
        if (searchval.length > 0) {
            if (searchval.length > 2) {
                setSuggestions(staticSuggestion(['loading...']));
                const searchUsersInAD = await actions.SearchUsersInAD(searchval);
                if (searchUsersInAD.length) {
                    let searchEmailInADArray = [];
                    let searchUsersInADArray = [];
                    searchUsersInAD.forEach(row => {
                        if (row.mail != null) {
                            searchUsersInADArray.push({
                                ntDomainId: 'NA',//Hardcoding since Activedirectory not returning this value. in future it should update.
                                emailAddress: row.mail,
                                employeeNumber: '0',//Hardcoding since Activedirectory not returning this value. in future it should update.
                                commonName: row.givenName,
                                givenName: row.givenName,
                                surname: row.surname
                            })
                            return searchEmailInADArray.push(row.mail);
                        }
                    });
                    setUserData(searchUsersInADArray);
                    setSuggestions(staticSuggestion([]));
                    setSuggestions(searchEmailInADArray);
                } else {
                    setSuggestions(staticSuggestion(['Nothing related found.Try different words']));
                }
            }
        }
        else {
            setSuggestions([]);
        }
    };

    const searchADfn = useCallback(debounce(filterSuggestion, 1000), []);

    const onChangeSearchAD = (event) => {
        setUserSuccessText("");
        setSearchKey(event.target.value);
        if (event.target.value.length > 0 && event.target.value.length < 3) {
            setSuggestions(staticSuggestion(['minimum three letters required']));
        } else {
            setSuggestions([]);
            searchADfn(event.target.value);
        }
    }

    const selectUserEmailAddressOnClick = async (val) => {
        setSearchKey(val);
    }

    return showLayer ?
        (
            <Layer
                onEsc={() => {
                    setShowLayer(false);
                    setSuggestions([]);
                    setSuggestions([]);
                    setSearchKey("");
                    setUserData("");
                    setUserSuccessText("");
                }
                }
                onClickOutside={() => {
                    setShowLayer(false);
                    setSuggestions([]);
                    setSearchKey("");
                    setUserData("");
                    setUserSuccessText("");
                }
                }>

                <Box
                    direction="column"
                    align="center"
                    justify="center"
                    pad="medium"
                >
                    <Form onSubmit={addUser}>
                        <Box direction="row" gap="small">
                            <Box width="medium">
                                <FormField name="users">
                                    <TextInput
                                        plain
                                        type="search"
                                        placeholder="Search User"
                                        onChange={onChangeSearchAD}
                                        value={searchKey}
                                        onSelect={event => {
                                            selectUserEmailAddressOnClick(event.suggestion);
                                        }}
                                        suggestions={suggestions}
                                    />
                                </FormField>
                            </Box>
                        </Box>
                        <Box direction="row" gap="small">
                            <Box width="small">
                                <Button disabled={!userData} type="submit" primary label="Save" />
                            </Box>
                            <Box width="small">
                                <Button
                                    primary
                                    label="Cancel"
                                    onClick={() => {
                                        setShowLayer(false);
                                        setSuggestions([]);
                                        setSearchKey("");
                                        setUserData("");
                                        setUserSuccessText("");
                                    }
                                    }
                                />
                            </Box>
                        </Box>
                        <Box gap="small" width="medium" pad={{ top: "2px" }}>
                            <Text size="20px">{userSuccessText}</Text>
                        </Box>
                    </Form>
                </Box>
            </Layer>
        ) :
        (
            <Button onClick={() => setShowLayer(true)}>
                <Box background="brand" pad="xsmall" round="xsmall">
                    Add User
            </Box>
            </Button>
        )
}

export default AddUser;
