import React, { useState, useEffect, useContext } from "react";
import { DataTable, Text, Box, Button } from "grommet";
import { Checkmark, Close } from "grommet-icons";
import moment from "moment";

import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import Loading from "../Loading";
import EmptyList from "../EmptyList";
import AttachGroup from "./AttachGroup";

const PendingRequests = ({ updateCounts }) => {
    const [requests, setRequests] = useState([]);
    const [update, setupdate] = useState(false);
    const [loading, setloading] = useState(true);
    const [groups, setGroups] = useState();

    const { storedNamespace } = useContext(NamespaceContext);

    useEffect(() => {
        (async () => {
            setloading(true);
            let res = await actions.GetRequests("pending", storedNamespace);
            setRequests(res);
            setloading(false);
            let groups = await actions.GetGroupsByNamespaceId(storedNamespace);
            // let groupNames = [];
            // if (groups && groups.length > 0) {
            //     groups.map((row) => {
            //         groupNames.push(row.name);
            //     });
            // }
            setGroups(groups);
        })();
    }, [update, storedNamespace]);

    useEffect(() => {
        updateCounts();
        // eslint-disable-next-line
    }, []);

    const approveRequest = async (id) => {
        await actions.ApproveRequest(id);
        setupdate(!update);
        updateCounts();
    };

    const denyRequest = async (id) => {
        await actions.DenyRequest(id);
        setupdate(!update);
        updateCounts();
    };

    return (
        <>
            {!loading ? (
                <>
                    {requests.length > 0 ? (
                        <DataTable
                            margin="medium"
                            columns={[
                                {
                                    property: "UserName",
                                    header: <Text>UserName</Text>,
                                    render: ({ User }) =>
                                        User && User.commonName
                                            ? User.commonName
                                            : "-"
                                },
                                {
                                    property: "Email",
                                    header: <Text>Email</Text>,
                                    render: ({ User }) =>
                                        User && User.emailAddress
                                            ? User.emailAddress
                                            : "-"
                                },
                                {
                                    property: "comments",
                                    header: <Text>Comments</Text>
                                },
                                {
                                    property: "Groups",
                                    header: <Text>Groups</Text>,
                                    render: ({ Group, id }) => (
                                        <AttachGroup
                                            group={Group}
                                            requestId={id}
                                            setUpdate={() => setupdate(!update)}
                                            groups={groups}
                                        />
                                    )
                                },
                                {
                                    property: "Requester",
                                    header: <Text>Requested By</Text>,
                                    render: ({ Requester }) =>
                                        Requester && Requester.emailAddress
                                            ? Requester.emailAddress
                                            : "-"
                                },
                                {
                                    property: "Requested On",
                                    header: <Text>Requested On</Text>,
                                    render: ({ createdAt }) =>
                                        moment(createdAt).format(
                                            window.GLOBAL_DATE_FORMAT
                                        )
                                },
                                {
                                    property: "Actions",
                                    header: <Text>Actions</Text>,
                                    render: ({ id }) => (
                                        <Box
                                            gap="small"
                                            direction="row"
                                            pad={{ vertical: "xsmall" }}
                                        >
                                            <Button>
                                                <Checkmark
                                                    size="24px"
                                                    onClick={() =>
                                                        approveRequest(id)
                                                    }
                                                />
                                            </Button>
                                            <Button>
                                                <Close
                                                    size="24px"
                                                    onClick={() =>
                                                        denyRequest(id)
                                                    }
                                                />
                                            </Button>
                                        </Box>
                                    )
                                }
                            ]}
                            data={requests}
                        />
                    ) : (
                            <EmptyList />
                        )}
                </>
            ) : (
                    <Loading />
                )}
        </>
    );
};

export default PendingRequests;
