import React from "react";
import { ClipLoader } from "react-spinners";
import { Box } from "grommet";

const Loading = ({ message }) => {
    return (
        <Box align="center" pad="xlarge" fill={true}>
            <ClipLoader
                sizeUnit={"px"}
                size={60}
                color="black"
                loading={true}
            />
            <Box pad={{ top: "medium" }}>{message ? message : "loading"}</Box>
        </Box>
    );
};

export default Loading;
