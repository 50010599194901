import React, { useState, useEffect, useContext } from "react";
import { Box, Layer, Text, Button } from "grommet";
import { Close, Down } from "grommet-icons";
import { NamespaceContext } from "../../store/namespace-store";
import AddNamespace from "./AddNamespace";
import { Redirect } from "react-router";

const NamespaceList = () => {
    const [selectedNamespaceName, setSelectedNamespaceName] = useState("");
    const [show, setShow] = useState(false);
    const [redirect, setredirect] = useState(false);

    const [form, showForm] = useState(false);
    const {
        updateNamespaceValue,
        namespaces,
        getNamespaces,
        storedNamespace
    } = useContext(NamespaceContext);

    useEffect(() => {
        setShow(true);
        getNamespaces();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setredirect(false);
    }, [redirect]);

    const onChange = selectedValue => {
        let selectedNamespace = namespaces.find(n => n.name === selectedValue);
        setSelectedNamespaceName(selectedValue);
        updateNamespaceValue(selectedNamespace.id);
        setredirect(true);
    };

    return (
        <Box>
            {redirect ? (
                <Redirect
                    to={{
                        pathname: `/`
                    }}
                />
            ) : (
                ""
            )}

            <Box
                pad="xsmall"
                onClick={() => setShow(true)}
                direction="row"
                gap="8px"
                round="small"
                elevation="xsmall"
                align="center"
            >
                <Text weight="bold" size="medium">
                    {selectedNamespaceName
                        ? selectedNamespaceName
                        : "select namespace"}
                </Text>
                <Down color="white" size="15px" />
            </Box>
            {show ? (
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}
                    animate={false}
                    margin={{ top: "large" }}
                >
                    <Box direction="column" style={{ minWidth: "250px" }}>
                        <Box
                            direction="row"
                            justify="between"
                            pad="small"
                            elevation="small"
                        >
                            <Text size="large" weight="bold">
                                select namespace
                            </Text>
                            <Close size="30px" onClick={() => setShow(false)} />
                        </Box>

                        <Box direction="row">
                            <Box
                                direction="column"
                                overflow={{ vertical: "scroll" }}
                                flex="grow"
                                style={{
                                    minHeight: "465px",
                                    maxHeight: "465px"
                                }}
                            >
                                {!form && storedNamespace && (
                                    <Button onClick={() => showForm(true)}>
                                        <Box
                                            margin="xsmall"
                                            background="brand"
                                            pad="xsmall"
                                            round="xsmall"
                                            align="center"
                                            flex="shrink"
                                        >
                                            Add Namespace
                                        </Box>
                                    </Button>
                                )}
                                {namespaces && namespaces.length > 0
                                    ? namespaces.map((namespace, index) => {
                                          return (
                                              <Box
                                                  direction="row"
                                                  key={index}
                                                  onClick={() => {
                                                      setShow(false);
                                                      onChange(namespace.name);
                                                  }}
                                                  hoverIndicator={true}
                                                  align="center"
                                                  pad="medium"
                                                  justify="start"
                                                  style={{
                                                      minWidth: "200px"
                                                  }}
                                              >
                                                  {namespace.name}
                                              </Box>
                                          );
                                      })
                                    : "loading namespaces..!"}
                            </Box>
                            {storedNamespace && form && (
                                <AddNamespace form={form} showForm={showForm} />
                            )}
                        </Box>
                    </Box>
                </Layer>
            ) : (
                ""
            )}
        </Box>
    );
};

export default NamespaceList;
