import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Layer, Form, FormField, TextInput } from "grommet";
import { NamespaceContext } from "../../store/namespace-store";
import * as actions from "../../actions/uam-actions";
import toast from "../../components/toster";

const AttachGroup = ({ roleName, groups, setUpdate }) => {
    const [showLayer, setShowLayer] = useState(false);

    const [allGroups, setAllGroups] = useState([]);

    const [group, setGroup] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const existingGroups = [];
    const { storedNamespace } = useContext(NamespaceContext);

    if (groups && groups.length > 0) {
        groups.map((group) => existingGroups.push(group.name));
    }

    const filterSuggestion = (value) => {
        let res = allGroups.filter((group) => {
            return group.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
        setSuggestions(res);
    };

    const attachGroup = async (groupName) => {
        setShowLayer(false);
        setGroup("");
        let existingRoles = [];

        let { Roles } = await actions.GetGroup(storedNamespace, groupName);
        existingRoles = Roles.map((role) => role.name);

        let roleNames = [...existingRoles, roleName];
        const res = await actions.AttachGroupsToRole(storedNamespace, {
            groupName,
            roleNames
        });
        if (res.success) {
            toast.success("Group(s) attached to a role successfully");
            setUpdate();
        } else {
            toast.warn("There is some issue. Please try again");
        }
    };

    useEffect(() => {
        (async () => {
            let res = await actions.GetGroupsByNamespaceId(storedNamespace);
            let names = [];
            if (res && res.length > 0) {
                res.map((row) => {
                    return names.push(row.name);
                });
            }

            setAllGroups(names);
            setSuggestions(names);
        })();
    }, [storedNamespace]);

    return showLayer ? (
        <Layer
            onEsc={() => setShowLayer(false)}
            onClickOutside={() => setShowLayer(false)}
        >
            <Box
                direction="column"
                align="center"
                justify="center"
                pad="medium"
            >
                <Form>
                    <Box width="medium">
                        <FormField name="groups" label="groups">
                            <TextInput
                                placeholder="select"
                                value={group}
                                onChange={(event) => {
                                    setGroup(event.target.value);
                                    filterSuggestion(event.target.value);
                                }}
                                onSelect={(event) => {
                                    let group = event.suggestion;
                                    setGroup(group);
                                }}
                                suggestions={suggestions}
                            />
                        </FormField>
                    </Box>
                    <Box direction="row" gap="small">
                        <Box width="small">
                            <Button
                                primary
                                label="Save"
                                onClick={() => attachGroup(group)}
                            />
                        </Box>
                        <Box width="small">
                            <Button
                                primary
                                label="Cancel"
                                onClick={() => setShowLayer(false)}
                            />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Layer>
    ) : (
        <Button onClick={() => setShowLayer(true)}>
            <Box background="brand" pad="xsmall" round="xsmall">
                Add Group
            </Box>
        </Button>
    );
};

export default AttachGroup;
