import React from "react";
import { Box, Text } from "grommet";

const EmptyList = () => {
    return (
        <Box direction="column" align="center" justify="center" pad="medium">
            <Text size="large">Nothing found to show you here.</Text>
        </Box>
    );
};

export default EmptyList;
