import React, { useState, useEffect, useContext } from "react";
import { DataTable, Text, Box } from "grommet";
import moment from "moment";

import Loading from "../Loading";
import EmptyList from "../EmptyList";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";

import AddUser from './AddUser'

const UsersList = ({ updateCounts }) => {
    const [users, setusers] = useState([]);
    const [loading, setloading] = useState(true);
    const { storedNamespace } = useContext(NamespaceContext);

    const [update, setupdate] = useState(false);

    useEffect(() => {
        updateCounts();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        (async () => {
            let users = {};
            setloading(true);
            if (storedNamespace) {                
                users = await actions.GetUsersByNameSpace(storedNamespace);                
            }
            setusers(users);
            setloading(false);
        })();
    }, [update, storedNamespace]);

    return (
        <>
            {!loading ? (
                <>
                    <Box
                        direction="row"
                        justify="end"
                        gap="large"
                        pad={{ right: "medium", bottom: "xxsmall" }}
                        margin="xxsmall"
                    >
                        <AddUser setupdate={() => setupdate(!update)} updateCounts={() => updateCounts()}></AddUser>
                    </Box>
                    {users.length > 0 ? (
                        <DataTable
                            margin="medium"
                            columns={[
                                {
                                    property: "commonName",
                                    header: <Text>User Name</Text>,
                                    render: ({ commonName }) => (
                                        <Box width="small">
                                            {commonName}
                                        </Box>
                                    )
                                },
                                {
                                    property: "emailAddress",
                                    header: <Text>Email</Text>
                                },
                                {
                                    property: "createdAt",
                                    header: <Text>Authorized On</Text>,
                                    render: ({ createdAt }) =>
                                        moment(createdAt).format(
                                            window.GLOBAL_DATE_FORMAT
                                        )
                                }
                            ]}
                            data={users}
                        />
                    ) : (
                            <EmptyList />
                        )}
                </>
            ) : (
                    <Loading />
                )}
        </>
    );
};

export default UsersList;
