import Error from "./error";
import Ok from "./ok";
import Info from "./info";
import Success from "./success";

const toast = {
    error: Error,
    ok: Ok,
    info: Info,
    success:Success
};

export default toast;
