import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({ to, styles = {}, children }) => {
    return (
        <Link to={to} style={Object.assign({ textDecoration: "none" }, styles)}>
            {children}
        </Link>
    );
};

export default NavLink;
