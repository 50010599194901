import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Error = (message, autoClose = 5000) => {
    toast.error(message, {
        autoClose: autoClose
    });
};

export default Error;
