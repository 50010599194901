import React, { useState } from "react";
import { Box, FormField, TextInput } from "grommet";
import * as actions from "../../actions/uam-actions";

const AttachGroup = ({ group, requestId, setUpdate, groups }) => {
    let deafultGroupName = '';
    if (group) {
        deafultGroupName = group.name
    }
    const [groupName, setGroup] = useState(deafultGroupName);
    const [suggestions, setSuggestions] = useState();

    const filterSuggestion = (value) => {
        let res = groups.filter((group) => {
            return group.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
        setSuggestions(res);
    };

    return (
        <>
            {
                groupName ? groupName :
                    <Box width="xmedium">
                        <FormField>
                            <TextInput
                                autoFocus={false}
                                placeholder="select"
                                value={groupName}
                                onClick={() => {
                                    let groupNames = [];
                                    if (groups && groups.length > 0) {
                                        groups.map((row) => {
                                            return groupNames.push(row.name);
                                        });
                                    }
                                    setSuggestions(groupNames)
                                }}
                                onChange={(event) => {
                                    setGroup(event.target.value);
                                    filterSuggestion(event.target.value);
                                }}
                                onSelect={async (event) => {
                                    let group = event.suggestion;
                                    let groupId = groups.filter((x) => {
                                        return x.name === group
                                    })[0].id;
                                    await actions.PatchRequest(requestId, groupId);
                                    setUpdate();
                                }}
                                suggestions={suggestions}
                            />
                        </FormField>
                    </Box>
            }
        </>
    );
};

export default AttachGroup;
