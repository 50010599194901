import useFetch from "../hooks/useFetch";

export const SSO = async () => {
    try {
        let res = await fetch(`${process.env.REACT_APP_UAM_API_URL}/access`);
        res = await res.json();
        return res.loginUrl;
    } catch (error) {
        throw new Error("server can't be reached");
    }
};

export const GetNewAccessToken = async (refreshtoken) => {
    let res;
    try {
        res = await fetch(`${process.env.REACT_APP_UAM_API_URL}/access`, {
            headers: {
                refreshtoken
            }
        });
        res = await res.json();
    } catch (err) {
        throw new Error("server can't be reached");
    }

    if (res.success) {
        return res;
    } else {
        throw new Error(res.message);
    }
};

export const GetRequests = async (status, namesapceId) => {
    try {
        let filteredRequests = [];
        const response = await useFetch(`/request/namespace/${namesapceId}`);
        const requests = response.data.AccessRequests.rows;
        requests.filter((request) => {
            if (request.status === status) {
                filteredRequests = [...filteredRequests, request];
            }
            return true;
        });
        return filteredRequests;
    } catch (err) {
        console.error(err);
    }
};

export const GetCounts = async (namespaceId) => {
    try {
        const counts = await useFetch(
            `/aggregate?aggFunction=count&entities=["policies","roles","groups","users","requests","apikeys"]&namespaceId=${namespaceId}`
        );
        return counts.data;
    } catch (err) {
        console.log(err);
    }
};

export const GetGroupsCount = async (namespaceId) => {
    try {
        const groups = await useFetch(`/namespaces/${namespaceId}/groups`);
        return groups.count;
    } catch (err) {
        console.log(err);
    }
};

export const GetGroups = async (namespaceId) => {
    try {
        let groups = await useFetch(`/namespaces/${namespaceId}/groups`);
        return groups.data;
    } catch (err) {
        console.error(err);
    }
};

export const GetGroupsByNamespaceId = async (namespaceId) => {
    try {
        let groups = await useFetch(`/namespaces/${namespaceId}/groups`);
        return groups.data;
    } catch (err) {
        console.error(err);
    }
};

export const GetGroup = async (namespaceId, groupName) => {
    try {
        const group = await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}`
        );
        return group.data;
    } catch (err) {
        console.error(err);
    }
};

export const AddGroup = async ({ name, description, namespaceId }) => {
    try {
        return await useFetch(`/namespaces/${namespaceId}/groups`, {
            method: "POST",
            body: JSON.stringify({
                name,
                description,
                namespaceId
            })
        });
    } catch (err) {
        console.error(err);
    }
};

export const UpdateGroup = async (
    namespaceId,
    groupName,
    { newGroupDetails }
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}`,
            {
                method: "PUT",
                body: JSON.stringify(newGroupDetails)
            }
        );
    } catch (err) {
        console.error(err);
    }
};

export const DeleteGroup = async (namespaceId, groupName) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}`,
            {
                method: "DELETE"
            }
        );
    } catch (err) {
        console.error(err);
    }
};

export const AttachRolesToGroup = async (
    namespaceId,
    { groupName, roleNames }
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}/roles`,
            {
                method: "POST",
                body: JSON.stringify({
                    roleNames
                })
            }
        );
    } catch (err) {
        console.error(err);
    }
};

export const DetachRolesFromGroup = async (
    namespaceId,
    { groupName, roleNames }
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}/roles`,
            {
                method: "POST",
                body: JSON.stringify({
                    roleNames
                })
            }
        );
    } catch (err) {
        console.error(err);
    }
};

export const AttachUsersToGroup = async (
    namespaceId,
    { groupName, emails }
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}/users`,
            {
                method: "POST",
                body: JSON.stringify({
                    emails
                })
            }
        );
    } catch (err) {
        console.error(err);
    }
};

export const DetachUsersFromGroup = async (
    namespaceId,
    { groupName, emails }
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}/users`,
            {
                method: "POST",
                body: JSON.stringify({
                    emails
                })
            }
        );
    } catch (err) {
        console.error(err);
    }
};

export const GetRolesCount = async (namespaceId) => {
    try {
        const roles = await useFetch(`/namespaces/${namespaceId}/roles`);
        return roles.count;
    } catch (err) {
        console.error(err);
    }
};

export const GetRoles = async (namespaceId) => {
    try {
        const roles = await useFetch(`/namespaces/${namespaceId}/roles`);
        return roles.data;
    } catch (err) {
        console.error(err);
    }
};

export const GetRolesByNamespaceId = async (namespaceId) => {
    try {
        const roles = await useFetch(`/namespaces/${namespaceId}/roles`);
        return roles.data;
    } catch (err) {
        console.error(err);
    }
};

export const GetRole = async (namespaceId, roleName) => {
    try {
        const role = await useFetch(
            `/namespaces/${namespaceId}/roles/${roleName}`
        );
        return role.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const AddRole = async ({ name, description, namespaceId }) => {
    try {
        return await useFetch(`/namespaces/${namespaceId}/roles`, {
            method: "POST",
            body: JSON.stringify({
                name,
                description,
                namespaceId
            })
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const DeleteRole = async (namespaceId, roleName) => {
    try {
        return await useFetch(`/namespaces/${namespaceId}/roles/${roleName}`, {
            method: "DELETE"
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const UpdateRole = async (namespaceId, roleName, { newRoleDetails }) => {
    try {
        await useFetch(`/namespaces/${namespaceId}/roles/${roleName}`, {
            method: "PUT",
            body: JSON.stringify(newRoleDetails)
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const AttachGroupsToRole = async (
    namespaceId,
    { groupName, roleNames }
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}/roles`,
            {
                method: "POST",
                body: JSON.stringify({
                    roleNames
                })
            }
        );
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const DetachGroupsFromRole = async (
    namespaceId,
    { groupName, roleNames }
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/groups/${groupName}/roles`,
            {
                method: "POST",
                body: JSON.stringify({
                    roleNames
                })
            }
        );
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const AttachPoliciesToRole = async (
    namespaceId,
    roleName,
    policyNames
) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/roles/${roleName}/policies`,
            {
                method: "POST",
                body: JSON.stringify({
                    policyNames
                })
            }
        );
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const DetachPoliciesFromRole = async (namespaceId, roleName, policy) => {
    try {
        return await useFetch(
            `/namespaces/${namespaceId}/roles/${roleName}/detachPolicies`,
            {
                method: "POST",
                body: JSON.stringify({
                    policy
                })
            }
        );
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const GetUsersCount = async () => {
    try {
        const users = await useFetch(`/user`);
        return users.data.count;
    } catch (err) {
        console.error(err);
    }
};

export const GetUsersByNameSpace = async (namespaceId = "") => {
    try {
        const users = await useFetch(`/user?namespaceid=${namespaceId}`);
        return users.data.rows;
    } catch (err) {
        console.error(err);
    }
};

export const GetUsers = async () => {
    try {
        const users = await useFetch(`/user`);
        return users.data.rows;
    } catch (err) {
        console.error(err);
    }
};

export const GetPoliciesCount = async (namespaceId) => {
    try {
        const policies = await useFetch(`/namespaces/${namespaceId}/policies`);
        return policies.length;
    } catch (err) {
        console.error(err);
    }
};

export const GetPolicies = async () => {
    try {
        let policies = await useFetch(
            `/user/${localStorage.getItem("userEmail")}/policies?namespace=${
                process.env.REACT_APP_UAM_NAMESPACE
            }`
        );
        return policies.data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const GetPoliciesByNamespaceId = async (namespaceId) => {
    try {
        // const policies = await useFetch(`/policy/namespace/${namespaceId}`);
        const policies = await useFetch(`/namespaces/${namespaceId}/policies`);
        return policies.data;
    } catch (err) {
        console.error(err);
    }
};

export const AddPolicy = async (namespaceId, policy) => {
    try {
        //'/policy
        return await useFetch(`/namespaces/${namespaceId}/policies`, {
            method: "POST",
            body: JSON.stringify(policy)
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const DeletePolicy = async (namespaceId, policyName) => {
    try {
        // '/policy/${id}'
        return await useFetch(`/namespaces/${namespaceId}/policies/${policyName}`, {
            method: "Delete",
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const UpdatePolicy = async (namespaceId, policyName, policy) => {
    try {
        await useFetch(`/namespaces/${namespaceId}/policies/${policyName}`, {
            method: "PUT",
            body: JSON.stringify(policy)
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const AccessRequest = async ({ userId, payload }) => {
    try {
        await useFetch(`/request/${userId}`, {
            method: "POST",
            body: JSON.stringify(payload)
        });

        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const ApproveRequest = async (id) => {
    try {
        await useFetch(`/request/${id}/approve`, {
            method: "POST"
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const DenyRequest = async (id) => {
    try {
        await useFetch(`/request/${id}/deny`, {
            method: "POST"
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

/* export const PatchRequest = async (id, groupId) => {
    try {
        await useFetch(`/request/${id}`, {
            method: "PATCH",
            body: JSON.stringify({ groupId })
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};*/


export const PatchRequest = async (id, groupId) => {
    try {
        await useFetch(`/request/${id}`, {
            method: "PATCH",
            body: JSON.stringify({ groupId }),
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const GetNamespacesByEmail = async (email) => {
    try {
        const namespaces = await useFetch(`/namespaces/search?email=${email}`);
        if (namespaces.data && namespaces.data.rows) {
            return namespaces.data.rows;
        } else {
            //TODO: handle opa serivce errors here
            return namespaces;
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const GetNamespacesByName = async (namespaceName) => {
    try {
        const namespaces = await useFetch(
            `/namespace/search?name=${namespaceName}`
        );
        return namespaces.data.rows;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const GetAllNamespaces = async (namespaceName) => {
    try {
        const namespaces = await useFetch(`/namespaces`);
        return namespaces.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const AddNamespace = async ({ name, description, path }) => {
    try {
        return await useFetch(`/namespaces`, {
            method: "POST",
            body: JSON.stringify({
                name,
                description,
                path
            })
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const SearchUsersInAD = async (searchkeyword) => {
    try {
        const useremailids = await useFetch(
            `/directory/user?filterBy=name&filterFunc=startswith&filterValue=${searchkeyword}`
        );
        return useremailids.data.value;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const AddUser = async (userdetails) => {
    try {
        await useFetch(`/user`, {
            method: "POST",
            body: JSON.stringify(userdetails)
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const GetUserByEmailAddress = async (emailAddress) => {
    try {
        const user = await useFetch(`/user/${emailAddress}`);
        return user.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const GetApiKeysByNameSpace = async (namespaceId = "") => {
    try {
        const apikeys = await useFetch(`/namespaces/${namespaceId}/apikeys`);
        return apikeys.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const AddApiKeys = async (apiKeyName, apiKey, namespaceId) => {
    try {
        return await useFetch(`/namespaces/${namespaceId}/apikeys`, {
            method: "POST",
            body: JSON.stringify({
                apiKeyName,
                apiKey
            })
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const UpdateApiKeys = async (apiKeyName, apiKey, namespaceId) => {
    try {
        await useFetch(`/namespaces/${namespaceId}/apikeys/${apiKeyName}`, {
            method: "PUT",
            body: JSON.stringify({
                apiKey
            })
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const DeleteApiKeys = async (namespaceId, apiKeyName) => {
    try {
        await useFetch(`/namespaces/${namespaceId}/apikeys/${apiKeyName}`, {
            method: "Delete"
        });
        return true;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
