import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    Layer,
    Form,
    FormField,
    TextInput,
    TextArea
} from "grommet";
import { Redirect } from "react-router";

import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";

const AddRole = ({ roles }) => {
    const [showLayer, setShowLayer] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [redirect, setredirect] = useState(false);
    const { storedNamespace } = useContext(NamespaceContext);

    const isRoleAlreadyExists = value => {
        let existingres = roles.filter(role => {
            return role.name.toLowerCase() === value.toLowerCase();
        });
        return existingres.length > 0 ? true : false;
    }

    const addRole = async event => {
        if (isRoleAlreadyExists(name)) { toast.error("Role with same name already exists") }
        else {
            event.preventDefault();
            setShowLayer(false);
            const res = await actions.AddRole({
                name,
                description,
                namespaceId: storedNamespace
            });
            if (res.success) {
                toast.success("Role created successfully");
                setredirect(true);
            }
            else {
                toast.warn("There is some issue. Please try again");
            }
        }
    };

    return (
        <>
            {redirect ? (
                <Redirect
                    to={{
                        pathname: `/admin/roles/:${name}`,
                        state: { name, description }
                    }}
                />
            ) : (
                    <>
                        <Button onClick={() => setShowLayer(true)}>
                            <Box background="brand" pad="xsmall" round="xsmall">
                                Add Role
                        </Box>
                        </Button>
                        {showLayer ? (
                            <Layer
                                onEsc={() => setShowLayer(false)}
                                onClickOutside={() => setShowLayer(false)}
                            >
                                <Box
                                    direction="column"
                                    justify="start"
                                    width="medium"
                                    pad="medium"
                                >
                                    <Form>
                                        <Box
                                            width="medium"
                                            pad={{ left: "small", top: "small" }}
                                        >
                                            <FormField
                                                name="name"
                                                label="Role Name"
                                            >
                                                <TextInput
                                                    value={name}
                                                    onChange={event => {
                                                        setName(event.target.value);
                                                    }}
                                                />
                                            </FormField>
                                            <FormField
                                                name="description"
                                                label="Description"
                                            >
                                                <TextArea
                                                    size="medium"
                                                    placeholder="enter desc"
                                                    value={description}
                                                    onChange={event => {
                                                        setDescription(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </FormField>
                                        </Box>
                                        <Box
                                            direction="row"
                                            gap="small"
                                            pad={{ top: "medium" }}
                                        >
                                            <Box width="small">
                                                <Button
                                                    type="submit"
                                                    primary
                                                    label="Save"
                                                    onClick={addRole}
                                                />
                                            </Box>
                                            <Box width="small">
                                                <Button
                                                    primary
                                                    label="Cancel"
                                                    onClick={() =>
                                                        setShowLayer(false)
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Form>
                                </Box>
                            </Layer>
                        ) : (
                                ""
                            )}
                    </>
                )}
        </>
    );
};

export default AddRole;
