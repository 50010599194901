import React, { useState, useEffect, useContext } from "react";
import { Box, Tabs, Tab } from "grommet";
import { Previous } from "grommet-icons";

import GeneralInfo from "./GeneralInfo";
import Roles from "./Roles";
import Users from "./Users";
import NavLink from "../NavLink";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";

const GroupView = props => {
    let data;
    try {
        data = props.location.state;
        if (!data) throw new Error("NOT_AVILBLE_FOR_DRCT_ACCESS");
    } catch (e) {
        if (e.message === "NOT_AVILBLE_FOR_DRCT_ACCESS")
            window.location.href = "/";
    }

    const [name, setName] = useState(data.name || "");
    const [usersCount, setUsersCount] = useState(0);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [rolesCount, setRolesCount] = useState(0);
    const [groupInfo, setGroupInfo] = useState({
        name: data.name,
        description: data.description,
        createdAt: data.createdAt,
        createdBy: data.createdBy
    });

    const { storedNamespace } = useContext(NamespaceContext);

    useEffect(() => {
        setName(data.name);
        setGroupInfo({
            name: data.name,
            description: data.description,
            createdAt: data.createdAt,
            createdBy: data.createdBy
        });
        return () => {};
    }, [data]);

    const [activeTab, setactiveTab] = useState(0);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        (async () => {
            if (name) {
                let { Users, Roles } = await actions.GetGroup(storedNamespace,name);
                setUsersCount(Users ? Users.length : 0);
                setUsers(Users);
                setRolesCount(Roles ? Roles.length : 0);
                setRoles(Roles);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, update]);

    const renderSwitch = activeTab => {
        switch (activeTab) {
            case 0:
                return <GeneralInfo groupInfo={groupInfo} />;
            case 1:
                return (
                    <Roles
                        roles={roles}
                        groupName={name}
                        setUpdate={() => setUpdate(!update)}
                    />
                );
            case 2:
                return (
                    <Users
                        users={users}
                        setUpdate={() => setUpdate(!update)}
                        groupName={name}
                    />
                );
            default:
                return "foo";
        }
    };

    const TabTitle = label => (
        <Box align="center">
            <strong>{label}</strong>
        </Box>
    );

    return (
        <>
            <Box
                margin={{
                    left: "xxsmall",
                    right: "xxsmall",
                    bottom: "xxsmall"
                }}
                height="xxsmall"
                justify="start"
                direction="row"
                gap="xxsmall"
                elevation="medium"
            >
                <NavLink to={{ pathname: "/" }}>
                    <Box align="center" pad="small">
                        <Previous size="20px" color="black" />
                    </Box>
                </NavLink>
                <Tabs activeIndex={activeTab} onActive={id => setactiveTab(id)}>
                    <Tab title={TabTitle(`General Info`)} />
                    <Tab title={TabTitle(`Roles(${rolesCount})`)} />
                    <Tab title={TabTitle(`Users(${usersCount})`)} />
                </Tabs>
            </Box>
            <Box>{renderSwitch(activeTab)}</Box>
        </>
    );
};

export default GroupView;
