import React, { useState, useContext } from "react";
import { DataTable, Text, Box, Button } from "grommet";
import { Trash } from "grommet-icons";
import moment from "moment";

import AddRole from "./AttachRole";
import EmptyList from "../EmptyList";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";
import { confirmAlert } from "react-confirm-alert";

const Roles = ({ roles, groupName, setUpdate }) => {
    let [counter] = useState(0);
    const { storedNamespace } = useContext(NamespaceContext);
    const existingRoles = [];

    roles.map((role) => existingRoles.push(role.name));

    const detachRole = async (role) => {
        await confirmAlert({
            title: "Confirm",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes, Delete it",
                    onClick: async () => {
                        let roleNames = existingRoles.filter((role1) => {
                            return role1 !== role;
                        });
                        const res = await actions.DetachRolesFromGroup(
                            storedNamespace,
                            { groupName, roleNames }
                        );
                        if (res.success) {
                            toast.success(
                                "Role detached from the group successfully"
                            );
                        } else {
                            toast.warn("There is some issue. Please try again");
                        }
                        setUpdate();
                    }
                },
                {
                    label: "No",
                    onClick: () => {}
                }
            ]
        });
    };
    return (
        <>
            <Box
                direction="row"
                justify="end"
                gap="large"
                pad={{ right: "medium", bottom: "xxsmall" }}
                margin="xxsmall"
            >
                <AddRole
                    groupName={groupName}
                    roles={roles}
                    setUpdate={() => setUpdate()}
                />
            </Box>
            {roles.length > 0 ? (
                <DataTable
                    margin="medium"
                    columns={[
                        {
                            property: "id",
                            header: <Text>Id</Text>,
                            render: () => {
                                counter = counter + 1;
                                return (
                                    <Box pad={{ vertical: "xsmall" }}>
                                        {counter}
                                    </Box>
                                );
                            }
                        },
                        {
                            property: "name",
                            header: <Text>Role</Text>,
                            primary: false,
                            render: ({ name }) => name
                        },
                        {
                            property: "description",
                            header: <Text>Description</Text>,
                            render: ({ description }) => description
                        },
                        {
                            property: "createdAt",
                            header: <Text>Created On</Text>,
                            render: ({ createdAt }) =>
                                moment(createdAt).format(
                                    window.GLOBAL_DATE_FORMAT
                                )
                        },
                        {
                            property: "Action",
                            header: <Text>Action</Text>,
                            render: ({ name }) => (
                                <Box
                                    gap="small"
                                    direction="row"
                                    pad={{ vertical: "xsmall" }}
                                >
                                    <Button>
                                        <Trash
                                            size="20px"
                                            onClick={() => detachRole(name)}
                                        />
                                    </Button>
                                </Box>
                            )
                        }
                    ]}
                    data={roles}
                />
            ) : (
                <EmptyList />
            )}
        </>
    );
};

export default Roles;
