import React, { useState, useEffect, useContext } from "react";
import { DataTable, Text, Box, Button } from "grommet";
import moment from "moment";
import { Trash, Edit } from "grommet-icons";

import Loading from "../Loading";
import AddPolicy from "./AddPolicy";
import EditPolicy from "./EditPolicy";
import EmptyList from "../EmptyList";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";
import { confirmAlert } from "react-confirm-alert";

const PoliciesList = ({ updateCounts }) => {
    const [policies, setpolicies] = useState([]);
    const [loading, setloading] = useState(true);
    const [update, setupdate] = useState(false);
    const [showLayer, setShowLayer] = useState(false);

    const [showLayer1, setShowLayer1] = useState(false);

    const [editPolicyData, setEditPolicyData] = useState({
        name: "",
        description: "",
        document: {}
    });

    const { storedNamespace } = useContext(NamespaceContext);

    useEffect(() => {
        (async () => {
            setloading(true);
            let policies = {};
            if (storedNamespace) {
                policies = await actions.GetPoliciesByNamespaceId(
                    storedNamespace
                );
            }
            setpolicies(policies);
            setloading(false);
        })();
    }, [update, storedNamespace]);

    useEffect(() => {
        updateCounts();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        !showLayer1 &&
            setEditPolicyData({ name: "", description: "", document: [] });
    }, [showLayer1]);

    const deletePolicy = async (name) => {
        await confirmAlert({
            title: "Confirm",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes, Delete it",
                    onClick: async () => {
                        const res = await actions.DeletePolicy(
                            storedNamespace,
                            name
                        );
                        if (res.success) {
                            toast.success("Policy deleted successfully");
                            setupdate(!update);
                            updateCounts();
                        } else {
                            toast.warn("There is some issue. Please try again");
                        }
                    }
                },
                {
                    label: "No",
                    onClick: () => {}
                }
            ]
        });
    };

    const editPolicy = async (data) => {
        let { id, name, description, document } = data;
        setEditPolicyData({
            id,
            name,
            description,
            document: JSON.parse(document)
        });
        setShowLayer1(true);
    };

    return (
        <>
            {!loading ? (
                <>
                    <Box
                        direction="row"
                        justify="end"
                        pad={{ right: "medium" }}
                        margin={{ top: "small" }}
                    >
                        <Button onClick={() => setShowLayer(true)}>
                            <Box background="brand" pad="xsmall" round="xsmall">
                                Add Policy
                            </Box>
                        </Button>
                        <AddPolicy
                            showLayer={showLayer}
                            policies={policies}
                            setShowLayer={setShowLayer}
                            setupdate={() => setupdate(!update)}
                            updateCounts={() => updateCounts()}
                        />
                        {editPolicyData.name && (
                            <EditPolicy
                                defaultState={editPolicyData}
                                showLayer1={showLayer1}
                                setShowLayer1={setShowLayer1}
                                setupdate={() => setupdate(!update)}
                                updateCounts={() => updateCounts()}
                            />
                        )}
                    </Box>
                    {policies.length > 0 ? (
                        <DataTable
                            margin="medium"
                            columns={[
                                {
                                    property: "name",
                                    header: <Text>Policy</Text>,
                                    primary: false,
                                    render: ({ name }) => <Box>{name}</Box>
                                },
                                {
                                    property: "description",
                                    header: <Text>Description</Text>,
                                    render: ({ description }) => (
                                        <Box width="xsmall">
                                            <Text truncate={true}>
                                                {description}
                                            </Text>
                                        </Box>
                                    )
                                },
                                {
                                    property: "Resource",
                                    header: <Text>Resource</Text>,
                                    render: ({ document }) => {
                                        let document1 = JSON.parse(document);
                                        return document1.map((doc, index) => {
                                            return (
                                                <Box key={`resource-${index}`}>
                                                    {doc.resource}
                                                </Box>
                                            );
                                        });
                                    }
                                },
                                {
                                    property: "Permissions",
                                    header: <Text>Permissions</Text>,
                                    render: ({ document }) => {
                                        let document1 = JSON.parse(document);
                                        return document1.map((doc, index) => {
                                            return (
                                                <Box key={index}>
                                                    {doc.actions.map(
                                                        (value, index) => {
                                                            return doc.actions
                                                                .length -
                                                                1 !==
                                                                index
                                                                ? `${value}, `
                                                                : value;
                                                        }
                                                    )}
                                                </Box>
                                            );
                                        });
                                    }
                                },
                                {
                                    property: "Effect",
                                    header: <Text>Effect</Text>,
                                    render: ({ document }) => {
                                        let document1 = JSON.parse(document);
                                        return document1.map((doc, index) => {
                                            return (
                                                <Box key={`effect-${index}`}>
                                                    {doc.effect}
                                                </Box>
                                            );
                                        });
                                    }
                                },
                                {
                                    property: "createdAt",
                                    header: <Text>Created On</Text>,
                                    render: ({ createdAt }) =>
                                        moment(createdAt).format(
                                            window.GLOBAL_DATE_FORMAT
                                        )
                                },
                                {
                                    property: "Action",
                                    header: <Text>Action</Text>,
                                    render: (data) => (
                                        <Box
                                            gap="small"
                                            direction="row"
                                            pad={{ vertical: "xsmall" }}
                                        >
                                            <Button>
                                                <Edit
                                                    size="20px"
                                                    onClick={() =>
                                                        editPolicy(data)
                                                    }
                                                />
                                            </Button>
                                            <Button>
                                                <Trash
                                                    size="20px"
                                                    onClick={() =>
                                                        deletePolicy(data.name)
                                                    }
                                                />
                                            </Button>
                                        </Box>
                                    )
                                }
                            ]}
                            data={policies}
                        />
                    ) : (
                        <EmptyList />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default PoliciesList;
