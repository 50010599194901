import React, { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { Mail } from "grommet-icons";
import { withRouter } from "react-router-dom";
import toast from "../../components/toster";

import { ReactComponent as RobotImage } from "./robot.svg";

const Serverdown = (props) => {
    const [error, setError] = useState();
    useEffect(() => {
        (async () => {
            let searchParam = props.location.pathname.replace("/error/", "");
            setError(searchParam);
            toast.error(searchParam, 10000);
        })();
    }, [props]);

    return (
        <Box fill>
            <Box fill="horizontal" basis="1/2" align="center" alignSelf="end">
                <RobotImage width="100%" />
            </Box>

            <Box
                fill
                align="center"
                justify="center"
                alignContent="center"
                basis="1/2"
                gap="medium"
            >
                {error && <Text>{`Error: ${error}`}</Text>}
                <Box
                    direction="row"
                    align="center"
                    gap="small"
                    pad={{ bottom: "medium" }}
                >
                    <Mail color="black" size="20px" />
                    <Text>
                        {`Email us at ${
                            process.env.REACT_APP_SUPPORT_EMAIL ||
                            "sc-web-platform-2.0-dev@hpe.com"
                        } and tell us what
            happened.`}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

export default withRouter((props) => <Serverdown {...props} />);
