import React, { useState, useEffect, useContext } from "react";
import { Box, Tabs, Tab } from "grommet";

import GroupsList from "../components/groups/GroupsList";
import RolesList from "../components/roles/RolesList";
import PoliciesList from "../components/policies/PoliciesList";
import UsersList from "../components/users/UsersList";
import Loading from "../components/Loading";
import PendingRequests from "../components/requests/PendingRequests";

import { NamespaceContext } from "../store/namespace-store";
import * as actions from "../actions/uam-actions";

import ApiKeysList from "../components/apikeys/ApiKeysList";

const Admin = props => {
    let data = 0;
    try {
        if (
            props &&
            props.location &&
            props.location.state &&
            props.location.state.activeTab
        ) {
            data = props.location.state.activeTab;
        }
        if (!data && !data === 0) {
            throw new Error("Unable to determine active Tab - Admin.js");
        }
    } catch (e) {
        console.error(e);
    }

    const [groupsCount, setgroupsCount] = useState(0);
    const [rolesCount, setRolesCount] = useState(0);
    const [policiesCount, setPoliciesCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [requestsCount, setRequestsCount] = useState(0);
    const [apiKeysCount, setApiKeysCount] = useState(0);

    const [loading, setloading] = useState(true);
    const [activeTab, setactiveTab] = useState(data);
    const [update, setupdate] = useState(false);
    const { storedNamespace } = useContext(NamespaceContext);

    useEffect(() => {
        (async () => {
            try {
                if (storedNamespace) {
                    let counts = await actions.GetCounts(storedNamespace);
                    if (counts) {
                        const { group, role, policy, user, requests, apikeys } = counts;
                        setgroupsCount(group ? group : 0);
                        setRolesCount(role ? role : 0);
                        setPoliciesCount(policy ? policy : 0);
                        setUsersCount(user ? user : 0);
                        setRequestsCount(requests ? requests : 0);
                        setApiKeysCount(apikeys ? apikeys : 0);
                    }
                } else {
                    setgroupsCount(0);
                    setRolesCount(0);
                    setPoliciesCount(0);
                    setUsersCount(0);
                    setRequestsCount(0);
                    setApiKeysCount(0);
                }
                setloading(false);
            } catch (e) {
                console.log(JSON.stringify(e));
            }
        })();
    }, [update, storedNamespace]);

    const renderSwitch = activeTab => {
        switch (activeTab) {
            case 0:
                return <GroupsList updateCounts={() => setupdate(!update)} />;
            case 1:
                return <RolesList updateCounts={() => setupdate(!update)} />;
            case 2:
                return <PoliciesList updateCounts={() => setupdate(!update)} />;
            case 3:
                return <UsersList updateCounts={() => setupdate(!update)} />;
            case 4:
                return <PendingRequests updateCounts={() => setupdate(!update)} />;
            case 5:
                return <ApiKeysList updateCounts={() => setupdate(!update)} />;
            default:
                return;
        }
    };

    useEffect(() => {
        setupdate(!update);
        // eslint-disable-next-line
    }, []);

    const TabTitle = label => (
        <Box align="center">
            <strong>{label}</strong>
        </Box>
    );

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                    <>
                        {storedNamespace && (
                            <>
                                <Box
                                    margin={{
                                        left: "xxsmall",
                                        right: "xxsmall",
                                        bottom: "xxsmall"
                                    }}
                                    height="xxsmall"
                                    justify="start"
                                    direction="row"
                                    gap="xxsmall"
                                    elevation="medium"
                                >
                                    <Tabs
                                        activeIndex={activeTab}
                                        onActive={id => setactiveTab(id)}
                                    >
                                        <Tab
                                            title={TabTitle(
                                                `Groups(${groupsCount})`
                                            )}
                                        />
                                        <Tab
                                            title={TabTitle(`Roles(${rolesCount})`)}
                                        />
                                        <Tab
                                            title={TabTitle(
                                                `Policies(${policiesCount})`
                                            )}
                                        />
                                        <Tab
                                            title={TabTitle(`Users(${usersCount})`)}
                                        />
                                        <Tab
                                            title={TabTitle(
                                                `Requests(${requestsCount})`
                                            )}
                                        />
                                        <Tab title={TabTitle(`Api Keys(${apiKeysCount})`)}></Tab>
                                    </Tabs>
                                </Box>
                                <Box>{renderSwitch(activeTab)}</Box>
                            </>
                        )}
                    </>
                )}
        </>
    );
};

export default Admin;
