import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import  '../toster/toast.css'
const Success = (message, autoClose = 5000) => {

    toast.success(message, {
        autoClose: autoClose,
        className: 'Toastify__toast Toastify__toast--success'
    });
};
export default Success;