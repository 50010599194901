import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Layer } from "grommet";
import { Redirect } from "react-router";

import Loading from "../Loading";
import * as actions from "../../actions/uam-actions";
import { UserContext } from "../../store/user-store";

const Login = (props) => {
    const [message, setmessage] = useState("Login Here..!");

    const { isAuthenticated } = useContext(UserContext);

    useEffect(() => {
        if (props.location.state && props.location.state.message)
            setmessage(message);
    }, [message, props]);

    const [showSpinner, setShowSpinner] = useState(false);

    const callSSO = async () => {
        localStorage.clear();
        if (process.env.REACT_APP_NODE_ENV === "local") {
            window.location.href = "/saml";
        }

        try {
            let res = await actions.SSO();
            if (res) {
                window.location.href = res;
                setShowSpinner(true);
            } else {
                throw new Error("sso failed");
            }
        } catch (error) {
            window.location.href = `/error/${error.message}`;
            setShowSpinner(false);
        }
    };

    return (
        <>
            {isAuthenticated && localStorage.getItem("userType") ? (
                <Redirect
                    to={{
                        pathname: `/`
                    }}
                />
            ) : (
                <Layer>
                    <Box
                        align="center"
                        margin={{
                            top: "medium",
                        }}
                        direction="column"
                        gap="small"
                        width="medium"
                    >
                        {showSpinner ? (
                            <Loading message="You will be redirected to SSO page for logging in..." />
                        ) : (
                            <>
                                <Box>{message}</Box>
                                <Box pad="medium">
                                    <Button>
                                        <Box
                                            background="brand"
                                            pad="small"
                                            round="xsmall"
                                            onClick={() => callSSO()}
                                        >
                                            Login with SSO
                                        </Box>
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </Layer>
            )}
        </>
    );
};

export default Login;
