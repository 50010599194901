import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { Box, Tabs, Tab } from "grommet";
import { Previous } from "grommet-icons";

import GeneralInfo from "./GeneralInfo";
import Policies from "./Policies";
import Groups from "./Groups";
// import NavLink from "../NavLink";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";

const RoleView = props => {
    let data;
    try {
        data = props.location.state;
        if (!data) throw new Error("NOT_AVILBLE_FOR_DRCT_ACCESS");
    } catch (e) {
        if (e.message === "NOT_AVILBLE_FOR_DRCT_ACCESS") {
            window.location.href = "/";
        }
    }

    const [name, setName] = useState(data.name);
    const [policiesCount, setPoliciesCount] = useState(0);
    const [groups, setGroups] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [groupsCount, setGroupsCount] = useState(0);
    const [roleInfo, setRoleInfo] = useState({
        name: data.name,
        description: data.description,
        createdAt: data.createdAt,
        createdBy: data.createdBy
    });
    const { storedNamespace } = useContext(NamespaceContext);

    useEffect(() => {
        setName(data.name);
        setRoleInfo({
            name: data.name,
            description: data.description,
            createdAt: data.createdAt,
            createdBy: data.createdBy
        });
        return () => {};
    }, [data]);

    const [activeTab, setactiveTab] = useState(0);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        (async () => {
            if (name) {
                let { Groups, Policies } = await actions.GetRole(storedNamespace, name);
                setGroupsCount(Groups ? Groups.length : 0);
                setGroups(Groups);
                setPoliciesCount(Policies ? Policies.length : 0);
                setPolicies(Policies);
            }
        })();
    }, [name, update, storedNamespace]);

    const renderSwitch = activeTab => {
        switch (activeTab) {
            case 0:
                return <GeneralInfo roleInfo={roleInfo} />;
            case 1:
                return (
                    <Policies
                        policies={policies}
                        roleName={name}
                        setUpdate={() => setUpdate(!update)}
                    />
                );
            case 2:
                return (
                    <Groups
                        groups={groups}
                        setUpdate={() => setUpdate(!update)}
                        roleName={name}
                    />
                );
            default:
                return "foo";
        }
    };

    const TabTitle = label => (
        <Box align="center">
            <strong>{label}</strong>
        </Box>
    );

    return (
        <>
            <Box
                margin={{
                    left: "xxsmall",
                    right: "xxsmall",
                    bottom: "xxsmall"
                }}
                height="xxsmall"
                justify="start"
                direction="row"
                gap="xxsmall"
                elevation="medium"
            >
                <Link to={{ pathname: "/", state: { activeTab: 1 } }}>
                    <Box align="center" pad="small">
                        <Previous size="20px" color="black" />
                    </Box>
                </Link>
                <Tabs activeIndex={activeTab} onActive={id => setactiveTab(id)}>
                    <Tab title={TabTitle(`General Info`)} />
                    <Tab title={TabTitle(`Policies(${policiesCount})`)} />
                    <Tab title={TabTitle(`Groups(${groupsCount})`)} />
                </Tabs>
            </Box>
            <Box>{renderSwitch(activeTab)}</Box>
        </>
    );
};

export default RoleView;
