import React, { useContext } from "react";
import { Button } from "grommet";
import { Logout } from "grommet-icons";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../store/user-store";

const SignOut = () => {
    const { signOut } = useContext(UserContext);
    let history = useHistory();

    const handleSignOut = () => {
        localStorage.clear();
        signOut();
        history.push("/login", {
            message: "You have been logged out of the system.Please re-login"
        });
    };

    return (
        <>
            <Button
                onClick={() => {
                    handleSignOut();
                }}
            >
                <Logout size="30px" />
            </Button>
        </>
    );
};

export default SignOut;
