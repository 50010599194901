import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Layer, Form, FormField, TextInput } from "grommet";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";
import * as actions from "../../actions/uam-actions";

const AttachPolicy = ({ roleName, policies, setUpdate }) => {
    const [showLayer, setShowLayer] = useState(false);

    const [allPolicies, setAllPolicies] = useState([]);
    const [policy, setPolicy] = useState("");
    const [suggestions, setSuggestions] = useState();
    const existingPolicies = [];
    const { storedNamespace } = useContext(NamespaceContext);

    if (policies && policies.length > 0) {
        policies.map(policy => existingPolicies.push(policy.name));
    }

    const filterSuggestion = value => {
        let res = allPolicies.filter(policy => {
            return policy.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
        setSuggestions(res);
    };

    const attachPolicy = async () => {
        setShowLayer(false);
        setPolicy("");

        let policyNames = [...existingPolicies, policy];
        const res =  await actions.AttachPoliciesToRole(storedNamespace, roleName, policyNames);
        if(res.success)
        {
            toast.success("Policie(s) attached to a role successfully");
            setUpdate();
        }        
    };

    useEffect(() => {
        (async () => {
            let res = await actions.GetPoliciesByNamespaceId(storedNamespace);
            let names = [];
            if (res && res.length > 0) {
                res.map(row => {
                    return names.push(row.name);
                });
            }

            setAllPolicies(names);
            setSuggestions(names);
        })();
    }, [storedNamespace]);

    return showLayer ? (
        <Layer
            onEsc={() => setShowLayer(false)}
            onClickOutside={() => setShowLayer(false)}
        >
            <Box
                direction="column"
                align="center"
                justify="center"
                pad="medium"
            >
                <Form onSubmit={attachPolicy}>
                    <Box width="medium">
                        <FormField name="policies" label="policies">
                            <TextInput
                                placeholder="select"
                                value={policy}
                                onChange={event => {
                                    setPolicy(event.target.value);
                                    filterSuggestion(event.target.value);
                                }}
                                onSelect={event => {
                                    let policy = event.suggestion;
                                    setPolicy(policy);
                                }}
                                suggestions={suggestions}
                            />
                        </FormField>
                    </Box>
                    <Box direction="row" gap="small">
                        <Box width="small">
                            <Button type="submit" primary label="Save" />
                        </Box>
                        <Box width="small">
                            <Button
                                type="submit"
                                primary
                                label="Cancel"
                                onClick={() => setShowLayer(false)}
                            />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Layer>
    ) : (
        <Button onClick={() => setShowLayer(true)}>
            <Box background="brand" pad="xsmall" round="xsmall">
                Add Policy
            </Box>
        </Button>
    );
};

export default AttachPolicy;
