import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    Form,
    FormField,
    TextInput,
    TextArea,
    Text
} from "grommet";

import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";

const AddNamespace = ({ form, showForm }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [path, setPath] = useState("");
    const { getNamespaces } = useContext(NamespaceContext);

    const addNamespace = async event => {
        event.preventDefault();

        showForm(false);
        const namespace = {
            name,
            description,
            path
        };
        const res = await actions.AddNamespace(namespace);
        if (res.success) {
            toast.success("Namespace added successfully");
            setDescription("");
            setName("");
            setPath("");
            getNamespaces();
        }
    };

    return (
        <>
            <Box direction="column" justify="start" width="medium" pad="small">
                {form ? (
                    <Form>
                        <Box
                            width="medium"
                            pad={{ left: "small", top: "small" }}
                        >
                            <FormField name="name" label="name">
                                <TextInput
                                    value={name}
                                    onChange={event => {
                                        setName(event.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField name="description" label="Description">
                                <TextArea
                                    size="medium"
                                    placeholder="enter desc"
                                    value={description}
                                    onChange={event => {
                                        setDescription(event.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField name="path" label="Path">
                                <TextArea
                                    size="medium"
                                    placeholder="path"
                                    value={path}
                                    onChange={event => {
                                        setPath(event.target.value);
                                    }}
                                />
                            </FormField>
                            <Text size="small">
                                {`example: ${JSON.stringify({
                                    name: "order book",
                                    description: "order book namespace",
                                    path: "/sc/orderbook/**"
                                })}`}
                            </Text>
                        </Box>

                        <Box
                            direction="row"
                            gap="small"
                            pad={{ top: "medium" }}
                        >
                            <Box width="small">
                                <Button
                                    type="submit"
                                    disabled={!(name && description && path)}
                                    primary
                                    label="Save"
                                    onClick={addNamespace}
                                />
                            </Box>
                            <Box width="small">
                                <Button
                                    primary
                                    label="Cancel"
                                    onClick={() => showForm(false)}
                                />
                            </Box>
                        </Box>
                    </Form>
                ) : (
                        ""
                    )}
            </Box>
        </>
    );
};

export default AddNamespace;
