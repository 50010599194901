import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StyledToastContainer = styled(ToastContainer)`
    &.notification__container {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`;

export default function Notifications() {
    return (
        <StyledToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            closeOnClick
            rtl={false}
            className="notification__container"
        />
    );
}
