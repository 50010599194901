import React, { useContext } from "react";
import { Box, Heading } from "grommet";
import { UserAdmin } from "grommet-icons";
import { withRouter } from "react-router-dom";

import NavLink from "./NavLink";
import SignOut from "./authentication/SignOut";
// import { NotificationWidget } from "@hpe/notification-widget";
// import MessageViewPanel from "./MessageViewPanel";
import NamespaceList from "./namespace/NamespaceList";

import { UserContext } from "../store/user-store";

const Header = props => {
    const { isAuthenticated } = useContext(UserContext);

    return (
        <>
            <Box
                direction="row"
                align="center"
                justify="between"
                background="brand"
                pad={{ right: "medium" }}
            >
                <Heading margin="small" level="2" textAlign="start">
                    <Box direction="row" gap="small">
                        <NavLink to="/" styles={{ color: "white" }}>
                            <UserAdmin size="30px" />
                            <strong> User Access Management</strong>
                        </NavLink>
                    </Box>
                </Heading>

                {isAuthenticated && (
                    <Box
                        direction="row"
                        justify="evenly"
                        gap="medium"
                        align="center"
                    >
                        {!props.location.pathname.includes("requestaccess") &&
                            !props.location.pathname.includes("error") &&
                            !props.location.pathname.includes("login") &&
                            localStorage.getItem("userType") ===
                                "SuperAdmin" && (
                                <Box pad={{ right: "medium" }}>
                                    <NamespaceList />
                                </Box>
                            )}

                        {/* <NotificationWidget
                                urlprefix={process.env.REACT_APP_NOTIFICATION_API_URL}
                                accessToken={
                                    localStorage.getItem("accessToken") || ""
                                }
                                interval={process.env.REACT_APP_NOTIOFICATION_INTERVAL}
                                type="bell"
                                messageViewPanel={MessageViewPanel}
                            /> */}
                        <SignOut />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default withRouter(props => <Header {...props} />);
