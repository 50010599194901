import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Layer, Form, FormField, TextInput } from "grommet";
import { NamespaceContext } from "../../store/namespace-store";

import * as actions from "../../actions/uam-actions";
import toast from "../../components/toster";

const AttachRole = ({ groupName, roles, setUpdate }) => {
    const [showLayer, setShowLayer] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [role, setRole] = useState("");
    const [suggestions, setSuggestions] = useState();
    const existingRoles = [];
    const { storedNamespace } = useContext(NamespaceContext);
    const [isRoleValid, setIsRoleValid] = useState(false);

    if (roles && roles.length > 0) {
        roles.map((role) => existingRoles.push(role.name));
    }

    const filterSuggestion = (value) => {
        let res = allRoles.filter((role) => {
            return role.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
        setSuggestions(res);
    };

    const checkIsRoleValid = (value) => {
        let res = allRoles.filter((role) => {
            return role.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
        return res.length > 0 ? true : false;
    };

    const canAttachRole = (value) => {
        let res = allRoles.findIndex(
            (role) => role.toLowerCase() === value.toLowerCase()
        );
        return res >= 0 ? true : false;
    };

    const isRoleAlreadyExists = (value) => {
        let existingres = existingRoles.findIndex(
            (role) => role.toLowerCase() === value.toLowerCase()
        );
        return existingres >= 0 ? true : false;
    };

    const attachRole = async () => {
        if (canAttachRole(role)) {
            if (!isRoleAlreadyExists(role)) {
                setShowLayer(false);
                setRole("");
                let roleNames = [...existingRoles, role];
                const res = await actions.AttachRolesToGroup(storedNamespace, {
                    groupName,
                    roleNames
                });
                if (res.success) {
                    toast.success("Role attached to a group successfully");
                } else {
                    toast.warn("There is some issue. Please try again");
                }
                setUpdate();
            } else {
                toast.warn("Selected role already exists");
            }
        } else {
            toast.warn("Please select proper role to proceed..");
        }
    };

    useEffect(() => {
        (async () => {
            let res = await actions.GetRolesByNamespaceId(storedNamespace);
            let names = [];
            res.map((row) => {
                return names.push(row.name);
            });
            setAllRoles(names);
            setSuggestions(names);
        })();
    }, [storedNamespace]);

    return showLayer ? (
        <Layer
            onEsc={() => setShowLayer(false)}
            onClickOutside={() => setShowLayer(false)}
        >
            <Box
                direction="column"
                align="center"
                justify="center"
                pad="medium"
            >
                <Form onSubmit={attachRole}>
                    <Box width="medium">
                        <FormField name="roles" label="roles">
                            <TextInput
                                placeholder="select"
                                value={role}
                                onChange={(event) => {
                                    setRole(event.target.value);
                                    setIsRoleValid(
                                        checkIsRoleValid(event.target.value)
                                    );
                                    filterSuggestion(event.target.value);
                                }}
                                onSelect={(event) => {
                                    let role = event.suggestion;
                                    setRole(role);
                                    setIsRoleValid(true);
                                }}
                                suggestions={suggestions}
                            />
                        </FormField>
                    </Box>
                    <Box direction="row" gap="small">
                        <Box width="small">
                            <Button
                                type="submit"
                                disabled={!(role && isRoleValid)}
                                primary
                                label="Save"
                            />
                        </Box>
                        <Box width="small">
                            <Button
                                type="submit"
                                primary
                                label="Cancel"
                                onClick={() => setShowLayer(false)}
                            />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Layer>
    ) : (
        <Button onClick={() => setShowLayer(true)}>
            <Box background="brand" pad="xsmall" round="xsmall">
                Add Role
            </Box>
        </Button>
    );
};

export default AttachRole;
