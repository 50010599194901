import React, { useState, useEffect, useContext } from "react";
import { DataTable, Text, Box, Button } from "grommet";
import moment from "moment";
import { Trash, Edit } from "grommet-icons";
import Loading from "../Loading";
import EmptyList from "../EmptyList";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import AddApiKeys from "./AddApiKeys";
import EditApiKeys from "./EditApiKeys";
import toast from "../../components/toster";

const ApiKeysList = ({ updateCounts }) => {
    const { storedNamespace } = useContext(NamespaceContext);
    const [update, setupdate] = useState(false);
    const [loading, setloading] = useState(true);
    const [apiKeys, setApiKeys] = useState([]);
    const [showLayer1, setShowLayer1] = useState(false);
    const [editApiKeyData, setEditApiKeyData] = useState({
        apiKeyName: "", apiKey: ""
    });

    useEffect(() => {
        updateCounts();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        !showLayer1 &&
            setEditApiKeyData({ apiKeyName: "", apiKey: "" });
    }, [showLayer1]);

    useEffect(() => {
        (async () => {
            let apiKeys = {};
            setloading(true);
            if (storedNamespace) {
                apiKeys = await actions.GetApiKeysByNameSpace(storedNamespace);
            }
            setApiKeys(apiKeys);
            setloading(false);
        })();
    }, [update, storedNamespace]);

    const editApiKey = async data => {
        let { id, apiKeyName, apiKey } = data;
        console.log(data);
        setEditApiKeyData({
            id,
            apiKeyName,
            apiKey
        });
        setShowLayer1(true);
    }

    const deleteApiKey = async name => {
        await actions.DeleteApiKeys(storedNamespace, name);
        setupdate(!update);
        updateCounts();
        toast.success("deleted successfully", 10000);
    }

    return (
        <>
            {!loading ? (
                <>
                    <Box
                        direction="row"
                        justify="end"
                        gap="large"
                        pad={{ right: "medium", bottom: "xxsmall" }}
                        margin="xxsmall"                    >
                        <AddApiKeys setupdate={() => setupdate(!update)} updateCounts={() => updateCounts()}></AddApiKeys>
                        {editApiKeyData.apiKeyName && (
                            <EditApiKeys
                                defaultState={editApiKeyData}
                                showLayer1={showLayer1}
                                setShowLayer1={setShowLayer1}
                                setupdate={() => setupdate(!update)}
                                updateCounts={() => updateCounts()}
                            />
                        )}
                    </Box>
                    {apiKeys.length > 0 ? (
                        <DataTable
                            margin="medium"
                            columns={[
                                {
                                    property: "apiKeyName",
                                    header: <Text>Key Name</Text>,
                                    render: ({ apiKeyName }) => (
                                        <Box>
                                            {apiKeyName}
                                        </Box>
                                    )
                                },
                                // {
                                //     property: "apiKey",
                                //     header: <Text>Key</Text>,
                                //     render: ({ apikey }) =>
                                //         "********"
                                // },
                                {
                                    property: "createdAt",
                                    header: <Text>Created On</Text>,
                                    render: ({ createdAt }) =>
                                        moment(createdAt).format(
                                            window.GLOBAL_DATE_FORMAT
                                        )
                                },
                                {
                                    property: "Action",
                                    header: <Text>Action</Text>,
                                    render: data => (
                                        <Box
                                            gap="small"
                                            direction="row"
                                            pad={{ vertical: "xsmall" }}
                                        >
                                            <Button>
                                                <Edit
                                                    size="20px"
                                                    onClick={() =>
                                                        editApiKey(data)
                                                    }
                                                />
                                            </Button>
                                            <Button>
                                                <Trash
                                                    size="20px"
                                                    onClick={() =>
                                                        deleteApiKey(data.apiKeyName)
                                                    }
                                                />
                                            </Button>
                                        </Box>
                                    )
                                }
                            ]}
                            data={apiKeys}
                        />
                    ) : (
                            <EmptyList />
                        )}
                </>
            ) : (
                    <Loading />
                )}
        </>
    );
};

export default ApiKeysList;