import React, { useState, useContext } from "react";
import { Box, Button, Layer, Form, FormField, TextInput, Text } from "grommet";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";

const AddPolicy = ({
    defaultState = {
        name: "",
        description: "",
        rules: ""
    },
    showLayer,
    policies,
    setShowLayer,
    setupdate,
    updateCounts
}) => {
    const [name, setName] = useState(defaultState.name || "");
    const [description, setDescription] = useState(
        defaultState.description || ""
    );
    const [rules, setRules] = useState(defaultState.rules || "");
    const { storedNamespace } = useContext(NamespaceContext);

    const editorStyles = {
        style: {
            container: {
                border: "1px solid grey"
            }
        },
        // colors: {
        //     default: "#666",
        //     background: "white",
        //     keys: "red"
        // },
        // theme: "light_mitsuketa_tribute",
        maxHeight: "350px",
        width: "600px"
    };

    const isPolicyAlreadyExists = (value) => {
        let existingres = policies.filter((policy) => {
            return policy.name.toLowerCase() === value.toLowerCase();
        });
        return existingres.length > 0 ? true : false;
    };

    const addPolicy = async (event) => {
        if (isPolicyAlreadyExists(name)) {
            toast.error("Policy already exists with the same name");
        } else {
            event.preventDefault();
            setShowLayer(false);
            const policy = {
                name,
                description,
                document: rules,
                namespaceId: storedNamespace
            };
            const res = await actions.AddPolicy(storedNamespace, policy);
            if (res.success) {
                toast.success("Policy created successfully");
                setupdate();
                updateCounts();
            } else {
                toast.warn("There is some issue. Please try again");
            }
        }
    };

    return (
        <>
            {showLayer ? (
                <Layer
                    onEsc={() => setShowLayer(false)}
                    onClickOutside={() => setShowLayer(false)}
                >
                    <Box
                        direction="column"
                        // justify="center"
                        // align="center"
                        // width="medium"
                        pad="medium"
                        // fill
                        overflow="scroll"
                    >
                        <Form>
                            <Box width="large" fill>
                                <FormField name="name" label="Policy Name">
                                    <TextInput
                                        value={name}
                                        onChange={(event) => {
                                            setName(event.target.value);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    name="description"
                                    label="Description"
                                >
                                    <TextInput
                                        size="medium"
                                        placeholder="enter desc"
                                        value={description}
                                        onChange={(event) => {
                                            setDescription(event.target.value);
                                        }}
                                    />
                                </FormField>

                                <FormField name="rules" label="Rules">
                                    <JSONInput
                                        id="a_unique_id"
                                        placeholder={[
                                            {
                                                resource: "",
                                                actions: [""],
                                                effect: ""
                                            }
                                        ]}
                                        locale={locale}
                                        {...editorStyles}
                                        // onKeyPressUpdate={false}
                                        onChange={({ jsObject }) => {
                                            setRules(jsObject);
                                        }}
                                        height="300px"
                                    />
                                </FormField>

                                <Text size="small">
                                    {`sample document: ${JSON.stringify({
                                        resource: "shortage",
                                        actions: ["list, edit"],
                                        effect: "allow/deny"
                                    })}`}
                                </Text>
                            </Box>

                            <Box
                                direction="row"
                                gap="small"
                                pad={{ top: "medium" }}
                            >
                                <Box width="small">
                                    <Button
                                        disabled={
                                            !(name && description && rules)
                                        }
                                        type="submit"
                                        primary
                                        label="Save"
                                        onClick={addPolicy}
                                    />
                                </Box>
                                <Box width="small">
                                    <Button
                                        primary
                                        label="Cancel"
                                        onClick={() => setShowLayer(false)}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                </Layer>
            ) : (
                ""
            )}
        </>
    );
};

export default AddPolicy;
