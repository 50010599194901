import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Layer, Form, TextInput, FormField } from 'grommet';
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import uuid from 'node-uuid';
import toast from "../../components/toster";

const AddApiKeys = ({ setupdate, updateCounts }) => {
    const [showLayer, setShowLayer] = useState(false);
    const [name, setApikeyname] = useState("");
    const [apikeyvalue, setApikeyvalue] = useState("");
    const { storedNamespace } = useContext(NamespaceContext);

    useEffect(() => {
        setApikeyvalue(uuid.v4());
    }, []);

    const addApiKeys = async event => {
        event.preventDefault();
        setShowLayer(false);
        await actions.AddApiKeys(
            name,
            apikeyvalue,
            storedNamespace
        );
        setupdate();
        updateCounts();
        toast.success("created successfully", 10000);
    };

    return showLayer ?
        (
            <Layer
                onEsc={() => {
                    setShowLayer(false);
                }
                }
                onClickOutside={() => {
                    setShowLayer(false);
                }
                }>

                <Box
                    direction="column"
                    align="center"
                    justify="center"
                    pad="medium"
                >
                    <Form>
                        <Box
                            width="medium"
                            pad={{ left: "small", top: "small" }}
                        >
                            <FormField
                                name="name"
                                label="Key Name"
                            >
                                <TextInput
                                    value={name}
                                    onChange={event => {
                                        setApikeyname(event.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField
                                name="apikeyvalue"
                                label="Key Value"
                            >
                                <TextInput
                                    value={apikeyvalue}
                                    maxLength={36}
                                    onChange={event => {
                                        setApikeyvalue(
                                            event.target.value
                                        );
                                    }}
                                />
                            </FormField>
                        </Box>
                        <Box
                            direction="row"
                            gap="small"
                            pad={{ top: "medium" }}
                        >
                            <Box width="small">
                                <Button
                                    type="submit"
                                    primary
                                    disabled={
                                        !(name && apikeyvalue)
                                    }
                                    label="Save"
                                    onClick={addApiKeys}
                                />
                            </Box>
                            <Box width="small">
                                <Button
                                    primary
                                    label="Cancel"
                                    onClick={() =>
                                        setShowLayer(false)
                                    }
                                />
                            </Box>
                        </Box>
                    </Form>
                </Box>
            </Layer>
        ) :
        (
            <Button onClick={() => setShowLayer(true)}>
                <Box background="brand" pad="xsmall" round="xsmall">
                    Add New Key
            </Box>
            </Button>
        )
}

export default AddApiKeys;
