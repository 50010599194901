import React from "react";
import { Grommet } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AppHeader from "./components/Header";
import Admin from "./containers/Admin";
import Notifications from "./containers/Notification";

import GroupView from "./components/groups/GroupView";
import RoleView from "./components/roles/RoleView";
import Saml from "./components/authentication/Saml";
import AuthGuard from "./components/authentication/AuthGuard";
import Login from "./components/authentication/Login";
import NotFound from "./components/NotFound";
import RequestAccess from "./components/RequestAccess";
import { NamespaceProvider } from "./store/namespace-store";
import { UserProvider } from "./store/user-store";
import ErrorPage from "./components/errors/ErrorPage";

const customTheme = Object.assign(
    {},
    {
        global: {
            input: {
                weight: 200
            }
        },
        tab: {
            border: {
                size: "4px",
                color: "none",
                hover: {
                    color: "dark-4"
                }
            },
            pad: {
                top: "xsmall",
                bottom: "xsmall",
                horizontal: "medium"
            },
            hover: {
                background: "light-3"
            }
        },
        tabs: {
            gap: "small"
        }
    },
    hpe
);

const App = () => {
    window.GLOBAL_DATE_FORMAT = "MM/DD/YYYY";

    return (
        <>
            <Notifications />
            <UserProvider>
                <NamespaceProvider>
                    <Grommet theme={customTheme} full>
                        <Router>
                            <AppHeader />
                            <Switch>
                                <AuthGuard exact path="/" component={Admin} />
                                <AuthGuard
                                    exact
                                    path="/admin/groups/:name"
                                    component={GroupView}
                                />
                                <AuthGuard
                                    exact
                                    path="/requestaccess"
                                    component={RequestAccess}
                                />
                                <AuthGuard
                                    exact
                                    path="/admin/roles/:name"
                                    component={RoleView}
                                />

                                <AuthGuard
                                    exact
                                    path="/admin/roles/:name"
                                    component={RoleView}
                                />

                                <Route exact path="/saml" component={Saml} />
                                <Route exact path="/login" component={Login} />
                                <Route
                                    exact
                                    path="/error/*"
                                    component={ErrorPage}
                                />
                                <Route path="*" component={NotFound} />
                            </Switch>
                        </Router>
                    </Grommet>
                </NamespaceProvider>
            </UserProvider>
        </>
    );
};

export default App;
