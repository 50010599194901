import React, { useState, useContext } from "react";
import { Box, Button, Layer, Form, FormField, TextInput, Text } from "grommet";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";

const AddPolicy = ({
    defaultState,
    showLayer1,
    setShowLayer1,
    setupdate,
    updateCounts
}) => {
    const [name, setName] = useState(defaultState.name || "");
    const [description, setDescription] = useState(
        defaultState.description || ""
    );
    const [document, setDocument] = useState(defaultState.document || []);

    const { storedNamespace } = useContext(NamespaceContext);

    const editorStyles = {
        style: {
            container: {
                border: "1px solid grey"
            }
        },
        // colors: {
        //     default: "#666",
        //     background: "white",
        //     keys: "red"
        // },
        // theme: "light_mitsuketa_tribute",
        maxHeight: "350px",
        width: "600px"
    };

    const addPolicy = async (event) => {
        event.preventDefault();
        setShowLayer1(false);
        const policy = {
            name,
            description,
            document
        };
        const res = await actions.UpdatePolicy(
            storedNamespace,
            defaultState.name,
            policy
        );
        if (res.success) {
            toast.success("Policy updated successfully");
            setupdate();
            updateCounts();
        } else {
            toast.warn("There is some issue. Please try again");
        }
    };

    return (
        <>
            {showLayer1 ? (
                <Layer
                    onEsc={() => setShowLayer1(false)}
                    onClickOutside={() => setShowLayer1(false)}
                >
                    <Box
                        direction="column"
                        // justify="center"
                        // align="center"
                        // width="medium"
                        pad="medium"
                        // fill
                        overflow="scroll"
                    >
                        <Form>
                            <Box width="large" fill>
                                <FormField name="name" label="Policy Name">
                                    <TextInput
                                        value={name}
                                        onChange={(event) => {
                                            setName(event.target.value);
                                        }}
                                    />
                                </FormField>
                                <FormField
                                    name="description"
                                    label="Description"
                                >
                                    <TextInput
                                        size="medium"
                                        placeholder="enter desc"
                                        value={description}
                                        onChange={(event) => {
                                            setDescription(event.target.value);
                                        }}
                                    />
                                </FormField>

                                <FormField
                                    name="document"
                                    label="document"
                                    // style={{ cursor: "pointer" }}
                                >
                                    <JSONInput
                                        id="a_unique_id"
                                        placeholder={document}
                                        {...editorStyles}
                                        locale={locale}
                                        onKeyPressUpdate={false}
                                        onChange={({ jsObject }) => {
                                            setDocument(jsObject);
                                        }}
                                        height="300px"
                                    />
                                </FormField>

                                <Text size="small">
                                    {`sample document: ${JSON.stringify({
                                        resource: "shortage",
                                        actions: ["list, edit"],
                                        effect: "allow/deny"
                                    })}`}
                                </Text>
                            </Box>

                            <Box
                                direction="row"
                                gap="small"
                                pad={{ top: "medium" }}
                            >
                                <Box width="small">
                                    <Button
                                        disabled={
                                            !(name && description && document)
                                        }
                                        type="submit"
                                        primary
                                        label="Save"
                                        onClick={addPolicy}
                                    />
                                </Box>
                                <Box width="small">
                                    <Button
                                        primary
                                        label="Cancel"
                                        onClick={() => {
                                            setShowLayer1(false);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                </Layer>
            ) : (
                ""
            )}
        </>
    );
};

export default AddPolicy;
