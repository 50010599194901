import React, { useState, useContext } from 'react';
import { Box, Button, Layer, Form, TextInput, FormField } from 'grommet';
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import toast from "../../components/toster";
//import { v4 as uuidv4 } from 'uuid';
import uuid from 'node-uuid';

const EditApiKeys = ({ defaultState, showLayer1, setShowLayer1, setupdate, updateCounts }) => {

    const [name, setApikeyname] = useState(defaultState.apiKeyName || "");
    const [apikeyvalue, setApikeyvalue] = useState("");
    const { storedNamespace } = useContext(NamespaceContext);

    const editApiKeys = async event => {
        event.preventDefault();
        setShowLayer1(false);

        await actions.UpdateApiKeys(name, apikeyvalue, storedNamespace);
        setupdate();
        updateCounts();
        toast.success("updated successfully", 10000);
    };

    const generateApiKeys = () => {
        setApikeyvalue(uuid.v4());
    }

    return (
        <>
            {showLayer1 ? (
                <Layer
                    onEsc={() => setShowLayer1(false)}
                    onClickOutside={() => setShowLayer1(false)}
                >
                    <Box
                        direction="column"
                        align="center"
                        justify="center"
                        pad="medium"
                    >
                        <Form>
                            <Box
                                width="medium"
                                pad={{ left: "small", top: "small" }}
                            >
                                <FormField
                                    name="name"
                                    label="Key Name"
                                >
                                    <TextInput
                                        value={name}
                                        readOnly={true}
                                        onChange={event => {
                                            setApikeyname(event.target.value);
                                        }}
                                    />
                                </FormField>
                                <Box direction="row">
                                    <Box width="medium">
                                        <FormField
                                            name="apikeyvalue"
                                            label="Key Value"
                                        >
                                            <TextInput
                                                value={apikeyvalue}
                                                maxLength={36}
                                                onChange={event => {
                                                    setApikeyvalue(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </FormField>
                                    </Box>
                                    <Box width="100px" pad={{ top: "50px" }} onClick={generateApiKeys}><Button primary>Generate</Button></Box>
                                </Box>
                            </Box>
                            <Box
                                direction="row"
                                gap="small"
                                pad={{ top: "medium" }}
                            >
                                <Box width="small">
                                    <Button
                                        type="submit"
                                        primary
                                        disabled={
                                            !(name && apikeyvalue)
                                        }
                                        label="Save"
                                        onClick={editApiKeys}
                                    />
                                </Box>
                                <Box width="small">
                                    <Button
                                        primary
                                        label="Cancel"
                                        onClick={() =>
                                            setShowLayer1(false)
                                        }
                                    />
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                </Layer>
            ) : (
                    ""
                )}
        </>
    );
};



export default EditApiKeys;