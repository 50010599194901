import React, { useState } from "react";

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
    const [userEmail, setUserEmail] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(
        localStorage.isAuthenticated
    );
    const [userType, setUserType] = useState("");

    return (
        <UserContext.Provider
            value={{
                userEmail: userEmail,
                isAuthenticated: isAuthenticated,
                userType: userType,
                updateUserEmail: emailId => {
                    setUserEmail(emailId);
                },
                signOut: async () => {
                    setIsAuthenticated(false);
                },
                signIn: async () => {
                    setIsAuthenticated(true);
                },
                updateUserType: userType => {
                    setUserType(userType);
                }
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
