import React, { useEffect, useContext } from "react";

import { isSuperAdmin, getUserEmail } from "../../helpers/token.helper";
import { Redirect } from "react-router";
import { Box, Layer } from "grommet";
import Loading from "../Loading";
import { UserContext } from "../../store/user-store";
import { accessTokenDev, refreshTokenDev } from "../../developmentToken";
import { setAccessToken, setRefreshToken } from "../../helpers/token.helper";

const getCookie = (cookieName) => {
    const v = document.cookie.match("(^|;) ?" + cookieName + "=([^;]*)(;|$)");
    return v ? v[2] : null;
};

const getToken = (cookieName) => {
    const cookie = getCookie(cookieName);
    if (cookie) {
        const tokenBuffer = new Buffer.from(cookie, "base64");
        return JSON.parse(tokenBuffer);
    }
    return null;
};

const objAccessToken = getToken("ckAccessToken");
const objRefreshToken = getToken("ckRefreshToken");

const Saml = () => {
    const { signIn, isAuthenticated } = useContext(UserContext);

    useEffect(() => {
        try {
            if (process.env.REACT_APP_NODE_ENV === "local") {
                setAccessToken(accessTokenDev);
                setRefreshToken(refreshTokenDev);
                localStorage.setItem("isAuthenticated", true);
                (async () => {
                    localStorage.setItem(
                        "userEmail",
                        await getUserEmail(accessTokenDev)
                    );
                    if (await isSuperAdmin(accessTokenDev)) {
                        localStorage.setItem("userType", "SuperAdmin");
                    } else {
                        localStorage.setItem("userType", "user");
                    }
                    signIn();
                })();
                return;
            }

            if (objAccessToken && objRefreshToken) {
                localStorage.setItem("accessToken", objAccessToken.accessToken);
                localStorage.setItem(
                    "refreshToken",
                    objRefreshToken.refreshToken
                );
                localStorage.setItem("isAuthenticated", true);
                (async () => {
                    localStorage.setItem(
                        "userEmail",
                        await getUserEmail(objAccessToken.accessToken)
                    );
                    if (await isSuperAdmin(objRefreshToken.refreshToken)) {
                        localStorage.setItem("userType", "SuperAdmin");
                    } else {
                        localStorage.setItem("userType", "user");
                    }
                    signIn();
                })();
            } else {
                window.location.href = '/error/Credentials not found.'
            }
        } catch (err) {
            console.error(err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isAuthenticated && localStorage.getItem("userType") ? (
                <Redirect
                    to={{
                        pathname: `/`,
                    }}
                />
            ) : (
                    <Layer>
                        <Box
                            align="center"
                            margin={{
                                top: "medium",
                            }}
                            direction="column"
                            gap="small"
                            width="medium"
                        >
                            <Loading message="Please wait while we fetch your grants" />
                        </Box>
                    </Layer>
                )}
        </>
    );
};

export default Saml;
