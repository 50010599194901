import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Button, Layer, Form, FormField, TextInput, Text } from "grommet";
import { NamespaceContext } from "../../store/namespace-store";
import * as actions from "../../actions/uam-actions";
import debounce from '../../../src/helpers/debounce';
import toast from "../../components/toster";


const AttachUser = ({ groupName, users, setUpdate }) => {

    const [showLayer, setShowLayer] = useState(false);

    const [allUsers, setAllUsers] = useState([]);
    const [user, setUser] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const existingUsers = [];
    const { storedNamespace } = useContext(NamespaceContext);
    const [userData, setUserData] = useState("");
    const [userSuccessText, setUserSuccessText] = useState("");


    users.map(user => existingUsers.push(user.emailAddress));

    const filterSuggestion = async value => {
        if (value.length >= 0) {
            let res = allUsers.filter(user => {
                return user.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            });

            if (!res.length) {
                debounceOnChange(value);
            } else {
                setSuggestions(staticSuggestion([]));
                setSuggestions(res);
            }
        }
    };

    const callSearchAD = async value => {
        setSuggestions(staticSuggestion(['loading...']));
        const adUsers = await actions.SearchUsersInAD(value);
        let adUsersArray = [];
        let searchUsersInADArray = [];
        if (adUsers.length) {
            adUsers.forEach(row => {
                if (row.mail != null) {
                    searchUsersInADArray.push({
                        ntDomainId: 'NA',//Hardcoding since Activedirectory not returning this value. in future it should update.
                        emailAddress: row.mail,
                        employeeNumber: '0',//Hardcoding since Activedirectory not returning this value. in future it should update.
                        commonName: row.givenName,
                        givenName: row.givenName,
                        surname: row.surname
                    })
                    return adUsersArray.push(row.mail);

                }
            });
            setUserData(searchUsersInADArray);
            setSuggestions(staticSuggestion([]));
            setSuggestions(adUsersArray);
        } else {
            setSuggestions(staticSuggestion(['Nothing related found.Try different words']));
        }
    }

    const debounceOnChange = useCallback(debounce(callSearchAD, 600), []);

    const staticSuggestion = (text) => {
        return [
            {
                label: (React.createElement(Box, { onClick: (event) => event.stopPropagation(), pad: "small", style: { opacity: 0.5, cursor: 'pointer' } }, text))
            }
        ];
    };


    const attachUser = async () => {
        setUser("");
        let emails = [...existingUsers, user];

        const checkuserexists = await actions.GetUserByEmailAddress(user);
        if (checkuserexists.user == null) {
            if (userData.length > 0) {
                let userOutput = userData.filter(users => { return users.emailAddress === user })
                if (userOutput.length >= 1) {
                    const userdetails = {
                        ntDomainId: userOutput[0].ntDomainId,
                        emailAddress: userOutput[0].emailAddress,
                        employeeNumber: userOutput[0].employeeNumber,
                        commonName: userOutput[0].commonName,
                        givenName: userOutput[0].givenName,
                        surname: userOutput[0].surname,
                    };
                    await actions.AddUser(userdetails);
                    toast.success("user added successfully", 10000);
                }
            }
        }
        let userGroups = await actions.GetGroup(storedNamespace, groupName);
        if (userGroups.Users.length > 0) {
            let userGroup = userGroups.Users.findIndex(item => user === item.emailAddress)
            if (userGroup > -1) {
                setUserSuccessText("user already attached to this group");
                setShowLayer(true);
            }

            else {                
                await actions.AttachUsersToGroup(storedNamespace, { groupName, emails });
                setShowLayer(false);
                getUsers();
                toast.success("user attached to this group successfully", 10000);
                setUserSuccessText("");
            }
        }
        else {                
            await actions.AttachUsersToGroup(storedNamespace, { groupName, emails });
            setShowLayer(false);
            getUsers();
            toast.success("user attached to this group successfully", 10000);
            setUserSuccessText("");
        }
        setUpdate(true);
    };

    const getUsers = async () => {
        let res = await actions.GetUsers();
        let names = [];
        res.map(row => {
            return names.push(row.emailAddress);
        });
        setAllUsers(names);
        setSuggestions(names);
    }
    useEffect(() => {
        (getUsers)();
    }, [storedNamespace]);

    return showLayer ? (
        <Layer
            onEsc={() => setShowLayer(false)}
            onClickOutside={() => setShowLayer(false)}
        >
            <Box
                direction="column"
                align="center"
                justify="center"
                pad="medium"
            >
                <Form onSubmit={attachUser}>
                    <Box direction="row" gap="small">
                        <Box width="medium">
                            <FormField name="users">
                                <TextInput
                                    placeholder="Search User"
                                    type="search"
                                    value={user}
                                    onChange={event => {
                                        setUser(event.target.value);
                                        //debounceOnChange(event.target.value);
                                        setUserSuccessText("");
                                        filterSuggestion(event.target.value);
                                    }}
                                    onSelect={event => {
                                        let user = event.suggestion;
                                        setUser(user);
                                    }}
                                    suggestions={suggestions}
                                />
                            </FormField>
                        </Box>
                    </Box>
                    <Box direction="row" gap="small">
                        <Box width="small">
                            <Button type="submit" disabled={!(user)} primary label="Save" />
                        </Box>
                        <Box width="small">
                            <Button
                                type="submit"
                                primary
                                label="Cancel"
                                onClick={() => {
                                    setShowLayer(false);
                                    setUserSuccessText("");
                                }}
                            />
                        </Box>
                    </Box>
                    <Box gap="small" width="medium" pad={{ top: "2px" }}>
                        <Text size="20px">{userSuccessText}</Text>
                    </Box>
                </Form>
            </Box>
        </Layer>
    ) : (
            <Button onClick={() => setShowLayer(true)}>
                <Box background="brand" pad="xsmall" round="xsmall">
                    Add User
            </Box>
            </Button>
        );
};

export default AttachUser;
