import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../../store/user-store";

const AuthGuard = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useContext(UserContext);
    return (
        <>
            {isAuthenticated ? (
                <Route {...rest} render={props => <Component {...props} />} />
            ) : (
                <Route
                    {...rest}
                    render={props => (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location.pathname,
                                    message:
                                        "You have been logged out of the system.Please re-login"
                                }
                            }}
                        />
                    )}
                />
            )}
        </>
    );
};

export default AuthGuard;
