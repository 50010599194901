import React, { useState, useEffect, useContext } from "react";
import { DataTable, Text, Box, Button } from "grommet";
import { Trash, Edit } from "grommet-icons";
import moment from "moment";

import NavLink from "../NavLink";
import AddRole from "./AddRole";
import Loading from "../Loading";
import EmptyList from "../EmptyList";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const RolesList = ({ updateCounts }) => {
    const [roles, setroles] = useState([]);
    const [loading, setloading] = useState(true);
    const [update, setupdate] = useState(false);
    const { storedNamespace } = useContext(NamespaceContext);

    useEffect(() => {
        (async () => {
            try {
                let roles = {};
                setloading(true);
                if (storedNamespace) {
                    roles = await actions.GetRolesByNamespaceId(
                        storedNamespace
                    );
                }
                setroles(roles);
                setloading(false);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [update, storedNamespace]);

    useEffect(() => {
        updateCounts();
        // eslint-disable-next-line
    }, []);

    const deleteRole = async (roleName) => {
        await confirmAlert({
            title: "Confirm",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes, Delete it",
                    onClick: async () => {
                        const res = await actions.DeleteRole(
                            storedNamespace,
                            roleName
                        );
                        if (res.success) {
                            toast.success("Role deleted successfully");
                            setupdate(!update);
                            updateCounts();
                        } else {
                            toast.warn("There is some issue. Please try again");
                        }
                    }
                },
                {
                    label: "No",
                    onClick: () => {}
                }
            ]
        });
    };

    return (
        <>
            {!loading ? (
                <>
                    <Box
                        direction="row"
                        justify="end"
                        pad={{ right: "medium" }}
                        margin={{ top: "small" }}
                    >
                        <AddRole roles={roles} />
                    </Box>
                    {roles.length > 0 ? (
                        <DataTable
                            margin="medium"
                            columns={[
                                {
                                    property: "name",
                                    header: <Text>Role</Text>,
                                    primary: false,
                                    render: ({ name }) => <Box>{name}</Box>
                                },
                                {
                                    property: "description",
                                    header: <Text>Description</Text>,
                                    render: ({ description }) => (
                                        <Box width="small">
                                            <Text truncate={true}>
                                                {description}
                                            </Text>
                                        </Box>
                                    )
                                },
                                {
                                    property: "Policies",
                                    header: <Text>Policies</Text>,
                                    render: ({ Policies }) => {
                                        let res = Policies.map(
                                            ({ name }, index) => {
                                                return Policies.length - 1 !==
                                                    index
                                                    ? `${name}, `
                                                    : name;
                                            }
                                        );
                                        return (
                                            <Box width="small">
                                                <Text truncate={false}>
                                                    {res}
                                                </Text>
                                            </Box>
                                        );
                                    }
                                },
                                {
                                    property: "createdAt",
                                    header: <Text>Created On</Text>,
                                    render: ({ createdAt }) =>
                                        moment(createdAt).format(
                                            window.GLOBAL_DATE_FORMAT
                                        )
                                },
                                {
                                    property: "Action",
                                    header: <Text>Action</Text>,
                                    render: (data) => (
                                        <Box
                                            gap="small"
                                            direction="row"
                                            pad={{ vertical: "xsmall" }}
                                        >
                                            <NavLink
                                                to={{
                                                    pathname: `/admin/roles/:${data.name}`,
                                                    state: { ...data }
                                                }}
                                                styles={{ color: "white" }}
                                            >
                                                <Edit size="20px" />
                                            </NavLink>
                                            <Button>
                                                <Trash
                                                    size="20px"
                                                    onClick={() =>
                                                        deleteRole(data.name)
                                                    }
                                                />
                                            </Button>
                                        </Box>
                                    )
                                }
                            ]}
                            data={roles}
                        />
                    ) : (
                        <EmptyList />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default RolesList;
