import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Button,
    Form,
    FormField,
    TextInput,
    TextArea
} from "grommet";
import { getUserEmail, getAccessToken } from "../helpers/token.helper";
import { withRouter } from "react-router-dom";
import toast from "../components/toster";
import { convertRequestParamsToObject } from "../helpers/url.helper";
import * as actions from "../actions/uam-actions";

const RequestAccess = (props) => {
    const [group, setGroup] = useState("");
    const [namespaceGroupNames, setNamespaceGroupNames] = useState([]);
    const [groupsSuggestions, setGroupsSuggestions] = useState(["loading..!"]);

    const [namespace, setNamespace] = useState("");
    const [allNamespaces, setAllNamespaces] = useState("");
    const [allNamespaceNames, setAllNamespaceNames] = useState([]);
    const [namespacesSuggestions, setNamespacesSuggestions] = useState([
        "loading..!"
    ]);

    const [message, setMessage] = useState("");
    const [email, setemail] = useState(localStorage.getItem("userEmail") || "");
    const [comments, setcomments] = useState("");

    const [isReqParamsEmail, setIsReqParamEmail] = useState(false);
    const [isReqParamsNamespace, setIsReqParamNamespace] = useState(false);
    const [isReqParamsGroup, setIsReqParamGroup] = useState(false);
    const [isReqParamsGroupAutorized, setIsReqParamGroupAutorized] = useState(
        true
    );

    const getNamespaces = async () => {
        if (!isReqParamsEmail && !isReqParamsNamespace) {
            let token = await getAccessToken();
            let loggedInUserId = await getUserEmail(token);
            setemail(loggedInUserId);
        }

        let namespaces = await actions.GetAllNamespaces();
        setAllNamespaces(namespaces);

        if (namespaces) {
            let names = [];
            namespaces.map((n) => {
                return names.push(n.name);
            });
            setAllNamespaceNames(names);
            setNamespacesSuggestions(names);
        }
    };

    useEffect(() => {
        (async () => {
            setGroup("");
            if (namespace) {
                let res = await actions.GetGroupsByNamespaceId(namespace.id);
                if (res) {
                    let names = [];
                    res.map((row) => {
                        return names.push(row.name);
                    });
                    setNamespaceGroupNames(names);
                    setGroupsSuggestions(names);
                } else {
                    setIsReqParamGroupAutorized(false);
                }
            }
        })();
    }, [namespace]);

    const filterGroupSuggestion = (value) => {
        let res = namespaceGroupNames;
        if (value) {
            res = namespaceGroupNames.filter((group) => {
                return group.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            });
        }
        setGroupsSuggestions(res);
    };

    const filterNamespaceSuggestion = (value) => {
        let res = allNamespaceNames;
        if (value) {
            res = allNamespaceNames.filter((ns) => {
                return ns.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            });
        }
        setNamespacesSuggestions(res);
    };

    useEffect(() => {
        //Read query parameters
        (async () => {
            const uri = props.location.search;
            const requestparams = convertRequestParamsToObject(uri);
            const {
                requestingFor = "",
                namespace = "",
                group = ""
            } = requestparams;
            if (requestingFor || namespace || group) {
                if (requestingFor) {
                    setIsReqParamEmail(true);
                    setemail(requestingFor);
                }
                namespace && setIsReqParamNamespace(true);
                if (group) {
                    setIsReqParamGroup(true);
                }

                //UAM-APP-URL/requestaccess?requestingFor=Rajashekhar.gundeti@hpe.com&namespace=281453D4-36CF-4A7F-86BC-F6C8F7EFC219&group=test
                //?requestingFor=kumar.b.lingaiah@hpe.com&namespace=281453D4-36CF-4A7F-86BC-F6C8F7EFC219&group=43272F4E-E0C9-4FCB-8721-241D19DA4FF7
                //?requestingFor=kumar.b.lingaiah@hpe.com&namespace=22db1c95-88e5-4214-b34d-fcf88b20d92a&group=372dd5fb-d8f7-46f5-8f24-f1fc330ae674

                if (namespace) {
                    let namespaces = await actions.GetAllNamespaces();
                    if (namespaces) {
                        let names = [];
                        namespaces.map((n) => {
                            return names.push(n.name);
                        });

                        setAllNamespaceNames(names);

                        let selectedNamespace = namespaces.find(
                            (x) =>
                                x.id === namespace ||
                                x.name === namespace.replace(/%20/g, " ")
                        );
                        if (selectedNamespace) {
                            setNamespace(selectedNamespace);
                            if (group) {
                                try {
                                    let res = await actions.GetGroupsByNamespaceId(
                                        namespace
                                    );
                                    if (!res) {
                                        setGroup(group);
                                        setIsReqParamGroupAutorized(false);
                                    } else {
                                        setIsReqParamGroupAutorized(true);
                                        if (res) {
                                            let names = [];
                                            res.map((row) => {
                                                return names.push(row.name);
                                            });
                                            setNamespaceGroupNames(names);
                                            let selectedGroup = res.find(
                                                (x) =>
                                                    x.id === group ||
                                                    x.name ===
                                                        group.replace(
                                                            /%20/g,
                                                            " "
                                                        )
                                            );
                                            if (selectedGroup) {
                                                setGroup(selectedGroup);
                                            } else {
                                                setGroup("");
                                                toast.error(
                                                    "invalid group name",
                                                    10000
                                                );
                                            }
                                        }
                                    }
                                } catch (err) {
                                    console.log(err);
                                }
                            }
                        } else {
                            setNamespace("");
                            toast.error("invalid namespace", 10000);
                        }
                    }
                }
            } else {
                setIsReqParamEmail(false);
                setIsReqParamNamespace(false);
                setIsReqParamGroup(false);
            }
        })();
    }, [props]);

    const accessRequest = async () => {
        let token = await getAccessToken();
        let loggedInUserId = await getUserEmail(token);
        let payloadObj = {
            requesterId: loggedInUserId,
            comments,
            namespaceId: namespace.id
        };

        if (group) {
            if (group.id) {
                payloadObj = { ...payloadObj, groupId: group.id };
            } else if (isReqParamsGroup) {
                payloadObj = { ...payloadObj, groupId: group };
            } else {
                payloadObj = { ...payloadObj, groupName: group };
            }
        }

        await actions.AccessRequest({
            userId: email,
            payload: payloadObj
        });
        setemail("");
        setGroup("");
        setNamespace("");
        setcomments("");
        setMessage(
            `we have notified your application admin about your request.Thank You..!`
        );
    };

    return (
        <Box
            direction="column"
            align="center"
            justify="center"
            pad={{ top: "small" }}
            gap="medium"
        >
            <Form>
                <Box width="medium">
                    <FormField name="email" label="User Email Address">
                        <TextInput
                            placeholder="enter email address"
                            value={email}
                            onChange={(event) => {
                                setemail(event.target.value);
                            }}
                        />
                    </FormField>

                    <FormField name="namespaces" label="namespaces">
                        <TextInput
                            onClick={async () => {
                                !allNamespaces && (await getNamespaces());
                                // filterNamespaceSuggestion(namespace.name);
                            }}
                            placeholder="select from the drop down list"
                            value={namespace.name || namespace}
                            onChange={(event) => {
                                setNamespace(event.target.value);
                                filterNamespaceSuggestion(event.target.value);
                                setGroup("");
                            }}
                            onSelect={(event) => {
                                // let namespace = event.suggestion;
                                let selectedNamespace = allNamespaces.find(
                                    (x) => x.name === event.suggestion
                                );
                                setNamespace(selectedNamespace);
                                setGroup("");
                            }}
                            suggestions={namespacesSuggestions}
                        />
                    </FormField>

                    <FormField name="groups" label="groups">
                        <TextInput
                            placeholder="select from the drop down list"
                            value={group.name || group}
                            onChange={(event) => {
                                setGroup(event.target.value);
                                filterGroupSuggestion(event.target.value);
                            }}
                            onSelect={(event) => {
                                // let group = event.suggestion;
                                let group = namespaceGroupNames.find(
                                    (g) => g === event.suggestion
                                );
                                setGroup(group);
                            }}
                            suggestions={groupsSuggestions}
                            disabled={!isReqParamsGroupAutorized}
                        />
                    </FormField>
                    <FormField name="comments" label="comments">
                        <TextArea
                            value={comments}
                            placeholder="add comments"
                            onChange={(event) =>
                                setcomments(event.target.value)
                            }
                        />
                    </FormField>
                </Box>

                <Button
                    // type="submit"
                    disabled={!(email && namespace && comments)}
                    onClick={() => accessRequest()}
                >
                    <Box
                        round="xsmall"
                        width="medium"
                        pad="small"
                        align="center"
                        background="brand"
                    >
                        Request Access
                    </Box>
                </Button>
            </Form>
            <Box width="large" pad="small" align="center">
                <Text weight="bold">{message}</Text>
            </Box>
        </Box>
    );
};

export default withRouter((props) => <RequestAccess {...props} />);