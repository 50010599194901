import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    Layer,
    Form,
    FormField,
    TextInput,
    TextArea
} from "grommet";
import { Redirect } from "react-router";
import toast from "../../components/toster";
import * as actions from "../../actions/uam-actions";
import { NamespaceContext } from "../../store/namespace-store";

const AddGroup = () => {
    const [showLayer, setShowLayer] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [redirect, setredirect] = useState(false);
    const { storedNamespace } = useContext(NamespaceContext);

    const addGroup = async event => {
        event.preventDefault();
        setShowLayer(false);
        const res = await actions.AddGroup({
            name,
            description,
            namespaceId: storedNamespace
        });
        if (res.success) {
            toast.success("Group created successfully");
        }
        else {
            toast.warn("There is some issue. Please try again");
        }
        setredirect(true);
    };

    return (
        <>
            {redirect ? (
                <Redirect
                    to={{
                        pathname: `/admin/groups/:${name}`,
                        state: { name, description }
                    }}
                />
            ) : (
                    <>
                        <Button onClick={() => setShowLayer(true)}>
                            <Box background="brand" pad="xsmall" round="xsmall">
                                Add Group
                        </Box>
                        </Button>
                        {showLayer ? (
                            <Layer
                                onEsc={() => setShowLayer(false)}
                                onClickOutside={() => setShowLayer(false)}
                            >
                                <Box
                                    direction="column"
                                    justify="start"
                                    width="medium"
                                    pad="medium"
                                >
                                    <Form>
                                        <Box
                                            width="medium"
                                            pad={{ left: "small", top: "small" }}
                                        >
                                            <FormField
                                                name="name"
                                                label="Group Name"
                                            >
                                                <TextInput
                                                    value={name}
                                                    onChange={event => {
                                                        setName(event.target.value);
                                                    }}
                                                />
                                            </FormField>
                                            <FormField
                                                name="description"
                                                label="Description"
                                            >
                                                <TextArea
                                                    size="medium"
                                                    placeholder="enter desc"
                                                    value={description}
                                                    onChange={event => {
                                                        setDescription(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </FormField>
                                        </Box>
                                        <Box
                                            direction="row"
                                            gap="small"
                                            pad={{ top: "medium" }}
                                        >
                                            <Box width="small">
                                                <Button
                                                    type="submit"
                                                    primary
                                                    disabled={
                                                        !(name && description)
                                                    }
                                                    label="Save"
                                                    onClick={addGroup}
                                                />
                                            </Box>
                                            <Box width="small">
                                                <Button
                                                    primary
                                                    label="Cancel"
                                                    onClick={() =>
                                                        setShowLayer(false)
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Form>
                                </Box>
                            </Layer>
                        ) : (
                                ""
                            )}
                    </>
                )}
        </>
    );
};

export default AddGroup;
