import jwtDecode from "jwt-decode";
import * as actions from "../actions/uam-actions";

export const isTokenExpired = async token => {
    try {
        const tokenPayload = jwtDecode(token);
        return tokenPayload.exp < Date.now().valueOf() / 1000;
    } catch (e) {
        return true;
    }
};

export const isAccessTokenExpired = async () => {
    let isAccessTokenExpired = await isTokenExpired(await getAccessToken());

    if (isAccessTokenExpired) {
        throw new Error("accessTokenExpiry");
    }
};

export const isRefreshTokenExpired = async () => {
    let isRefreshTokenExpired = await isTokenExpired(await getRefreshToken());
    if (isRefreshTokenExpired) {
        throw new Error("refreshTokenExpiry");
    }
};

export const getAccessToken = async () => {
    return localStorage.getItem("accessToken");
};

export const getRefreshToken = async () => {
    return localStorage.getItem("refreshToken");
};

export const setAccessToken = async token => {
    localStorage.setItem("accessToken", token);
    return;
};

export const setRefreshToken = async token => {
    localStorage.getItem("refreshToken", token);
    return;
};

export const getUserPolicies = async (token, namespace) => {
    try {
        const payload = jwtDecode(token);
        const policies = payload.userClaims.policies;
        return policies.filter(policy => policy.namespace === namespace);
    } catch (error) {
        console.error(error);
    }
};

export const getUserEmail = async token => {
    try {
        const payload = jwtDecode(token);
        return payload.userClaims.emailAddress;
    } catch (error) {
        console.error(error);
        return;
    }
};

export const fetchToken = async () => {
    try {
        await isAccessTokenExpired();
        return await getAccessToken();
    } catch (error) {
        if (error.message === "accessTokenExpiry") {
            try {
                let res = await actions.GetNewAccessToken(
                    await getRefreshToken()
                );
                let { accessToken, refreshToken } = res.data;
                await setAccessToken(accessToken);
                await setRefreshToken(refreshToken);
                return await getAccessToken();
            } catch (error) {
                if (error.message === "refreshTokenExpiry") {
                    localStorage.clear();
                    window.location.href = "/";
                } else console.log(error);
            }
        }
    }
};

export const isSuperAdmin = async () => {
    let policies = await actions.GetPolicies(localStorage.userEmail);
    try {
        if (policies) {
            let res = policies.filter(
                policy => policy.namespace === process.env.REACT_APP_UAM_NAMESPACE
            );

            res = res[0].permissions.map(permission => {
                return permission.action === "*" &&
                    permission.effect === "allow" &&
                    permission.resource === "*"
                    ? true
                    : false;
            });

            return !res.includes(false);
        }
        return false;
    } catch (e) {
        return false;
    }
};
